import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';

import findIcon from '@components/_ui/_blocks/Icons/helpers/findIcon';

import Flyout from '@components/_ui/Flyout';

import { Location } from '@reach/router';

import { useNavigationContext } from '@data/context/navigationContext';
import { useAccountNavigationContext } from '@data/context/accountContext/accountNavigationContext';
import IconChevronRight from '@components/_ui/_blocks/Icons/Navigation/IconChevronRight';
import BaseIcon from '@components/_ui/_blocks/Icons/BaseIcon';
import IconHome from '@components/_ui/_blocks/Icons/Navigation/IconHome';
import IconExit from '@components/_ui/_blocks/Icons/IconExit';
import IconAccount from '@components/_ui/_blocks/Icons/IconAccount';
import { useAuthContext } from '../../../../data/context/authContext';
import CraftMenuLink from '../../CraftMenuLink/CraftMenuLink';

/** Mobile navigation */
const SidebarNavigation = () => {
    const { isLoggedIn, logout } = useAuthContext();

    const { sidebarNavIsOpen, setSidebarNavIsOpen } = useNavigationContext();
    const { setAccountNavIsOpen } = useAccountNavigationContext();

    const onLinkClick = () => setSidebarNavIsOpen(false);

    const data = useStaticQuery(graphql`
        {
            craftcms {
                links: nodes(navHandle: "mobileNavigation", level: 1) {
                    ...sidebarLinks
                }
            }
        }
    `);

    const { links } = data.craftcms;
    let signinCta = null;

    if (!isLoggedIn) {
        signinCta = (
            <div className="nav-sidebar__cta">
                <Link to="/login" className="button-cta" onClick={onLinkClick}>
                    Sign in
                </Link>
            </div>
        );
    }

    const handleLogout = () => logout();

    return (
        <Location>
            {({ location }) => (
                <Flyout
                    isOpen={sidebarNavIsOpen}
                    title="Menu"
                    onClose={() => {
                        setSidebarNavIsOpen(false);
                    }}
                    className={`nav-sidebar ${!isLoggedIn ? '_not-logged-in' : ''}`}
                    pinnedChildren={signinCta}
                >
                    <nav>
                        <div className={`nav-sidebar__item ${location.pathname === '/' ? ' _is-active' : ''}`}>
                            <Link to="/" className="nav-sidebar__link" title="Home" onClick={onLinkClick}>
                                <BaseIcon className="icon--medium icon--moss">
                                    <IconHome />
                                </BaseIcon>
                                <span className="text-heading">Home</span>
                            </Link>
                        </div>
                        {isLoggedIn && (
                            <div className="nav-sidebar__item">
                                <button
                                    type="button"
                                    className="nav-sidebar__button flex flex--align-center flex--space-between"
                                    onClick={() => {
                                        setSidebarNavIsOpen(false);
                                        setAccountNavIsOpen(true);
                                    }}
                                >
                                    <div className="breathe vertical-align--middle">
                                        <BaseIcon className="icon--medium icon--moss">
                                            <IconAccount />
                                        </BaseIcon>
                                        <span className="text-heading">Your account</span>
                                    </div>
                                    <BaseIcon className="icon--xxsmall icon--slate">
                                        <IconChevronRight />
                                    </BaseIcon>
                                </button>
                            </div>
                        )}
                        {links.map((link, i) => (
                            <div className="nav-sidebar__item" key={i}>
                                <CraftMenuLink
                                    className="nav-sidebar__link"
                                    title={link.title}
                                    menuNode={link}
                                    onClick={link.children.length < 1 ? onLinkClick : null}
                                >
                                    <BaseIcon className="icon--medium icon--moss">{findIcon(`icon-${link.navigationIcon[0]}`)}</BaseIcon>
                                    <span className="text-heading">{link.title}</span>
                                </CraftMenuLink>
                                {link.children.length ? (
                                    <ul className="nav-sidebar__subitem">
                                        {link.children.map((child, a) => (
                                            <li key={a}>
                                                <CraftMenuLink menuNode={child} className="text-metadata" onClick={onLinkClick}>
                                                    {child.title}
                                                </CraftMenuLink>
                                            </li>
                                        ))}
                                    </ul>
                                ) : null}
                            </div>
                        ))}
                        {isLoggedIn && (
                            <div className="nav-sidebar__item">
                                <button type="button" className="nav-sidebar__button" title="Sign out of this account" onClick={handleLogout}>
                                    <BaseIcon className="icon--medium icon--moss">
                                        <IconExit />
                                    </BaseIcon>
                                    <span className="text-heading">Sign out</span>
                                </button>
                            </div>
                        )}
                    </nav>
                </Flyout>
            )}
        </Location>
    );
};

export default SidebarNavigation;
