/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const HeaderPPC = ({ className }) => (
    <header className={`header header--ppc ${className}`}>
        <div className="header__top ">
            <Link to="/" className="header__logo" title="The Independent Pharmacy homepage" aria-label="The Independent Pharmacy homepage">
                <img src="/images/logo.svg" alt="The Independent Pharmacy" width="125" className="display--block" />
            </Link>
        </div>
    </header>
);

HeaderPPC.defaultProps = {
    className: undefined,
};

HeaderPPC.propTypes = {
    className: PropTypes.string,
};

export default HeaderPPC;
