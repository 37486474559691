import React from 'react';
import PropTypes from 'prop-types';

const BaseIcon = ({ children, width, height, vx, vy, vw, vh, className, onClick, preserveAspectRatio }) => {
    const handleClick = (e) => {
        e.preventDefault();

        if (onClick && typeof onClick === 'function') onClick();
    };

    return (
        <svg
            viewBox={`${vx} ${vy} ${vw} ${vh}`}
            width={width}
            height={height}
            className={`icon icon--disable-events ${className}`}
            onClick={handleClick}
            preserveAspectRatio={preserveAspectRatio}
        >
            {children}
        </svg>
    );
};

BaseIcon.defaultProps = {
    width: 24,
    height: 24,
    vx: 0,
    vy: 0,
    vw: 48,
    vh: 48,
    className: '',
    onClick: undefined,
    preserveAspectRatio: undefined,
};

BaseIcon.propTypes = {
    /** The string to determine what svg icon we should be using. */
    children: PropTypes.node.isRequired,
    /** Width of the svg. */
    width: PropTypes.number,
    /** Height of the svg. */
    height: PropTypes.number,
    /** Viewbox x. */
    vx: PropTypes.number,
    /** Viewbox y. */
    vy: PropTypes.number,
    /** Viewbox width. */
    vw: PropTypes.number,
    /** Viewbox height. */
    vh: PropTypes.number,
    /** CSS Class that gets applied to the svg. */
    className: PropTypes.string,
    /** Click event applied to svg */
    onClick: PropTypes.func,
    /** Aspect Ratio */
    preserveAspectRatio: PropTypes.string,
};

export default BaseIcon;
