import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { timedModifiersShape, quantityShape } from '../../../propTypes/treatmentShape';
import getDefaultTimedQuantity from './helpers/getDefaultTimedQuantity';
import stringToInteger from './helpers/stringToInteger';
import { calculateNumberOfPillsByDays, calculateNumberOfDaysByPills } from './helpers/calculatePillsAndDays';

import Input from '../../../../Forms/Inputs/Input/Input';
import FieldWrapper from '../../../../Forms/FieldWrapper/FieldWrapper';
import Error from '../../../../Forms/Validation/Error';
import HelperInline from '../../../../Forms/Helper/HelperInline';

// TODO: Fix added to stop system looping, should probably spend time looking for a better solution.

const TimedQuantity = ({ modifiers, explanation, quantity, onChange }) => {
    const [numberOfPills, setNumberOfPills] = useState(getDefaultTimedQuantity());
    const [numberOfDays, setNumberOfDays] = useState(
        Number.isInteger(numberOfPills) ? calculateNumberOfDaysByPills(numberOfPills, modifiers) : ''
    );
    const [isDirty, setIsDirty] = useState(false);

    /**
     * If the input field for number of pills is valid or not.
     */
    const isPillInputValid = isDirty ? !!(numberOfPills > 0 && modifiers.max && numberOfPills <= modifiers.max) : true;

    /**
     * Updates the number of days and the number of pills based on those days. Also sets isDirty to
     * true.
     * @param {object} e - Input event object.
     */
    const handleDayChange = (e) => {
        setIsDirty(true);

        const days = stringToInteger(e.target.value);

        if (days === '') {
            setNumberOfDays('');
            setNumberOfPills('');
            return;
        }

        setNumberOfDays(days);
        setNumberOfPills(calculateNumberOfPillsByDays(days, modifiers));
    };

    /**
     * Updates the number of pills and sets isDirty to true.
     * @param {object} e - window event object.
     */
    const handlePillChange = (e) => {
        setIsDirty(true);

        const pills = stringToInteger(e.target.value);
        if (pills === '') {
            setNumberOfDays('');
            setNumberOfPills('');
            return;
        }

        setNumberOfPills(pills);
        setNumberOfDays(calculateNumberOfDaysByPills(pills, modifiers));
    };

    /**
     * Gets the current price by the number of pills requested.
     */
    const getPrice = useMemo(() => {
        if (!numberOfPills) return 0;

        if (numberOfPills > modifiers.max) return modifiers.max * quantity.unit_cost;

        return numberOfPills ? numberOfPills * quantity.unit_cost : 0;
    }, [numberOfPills, quantity, modifiers]);

    /**
     * On "numberOfPills" change, update the quantity of the treatment. If no quantity is provided,
     * an object with a price of 0 is returned.
     * Onchange the price and quantity are passed into the callback function.
     */
    useEffect(() => {
        onChange({
            quantity: modifiers.max && numberOfPills <= modifiers.max ? numberOfPills : undefined,
            price: getPrice,
            label: quantity.label,
            unit_cost: quantity.unit_cost,
        });
        // Ignoring onChange function, unit_cost and label from deps as these will not change.
    }, [numberOfPills, modifiers, getPrice]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * If the number of pills is greater than the max, generate an error message.
     */
    const maxPillErrorMessage = useMemo(() => {
        if (!modifiers.max || numberOfPills < modifiers.max) return null;

        const message = `You cannot purchase more than <strong>${modifiers.max}</strong> pills.`;
        return <Error>{message}</Error>;
    }, [modifiers, numberOfPills]);

    /**
     * If the user has interacted before and no amount has been set, generate an error message.
     */
    const noAmountErrorMessage = useMemo(() => {
        if (isDirty === false || numberOfPills > 0) return null;
        return <Error>Please enter the quantity of pills you will need, either by days or number.</Error>;
    }, [isDirty, numberOfPills]);

    return (
        <div className="flow--medium">
            <p className="text-metadata">
                Enter the number of days you're in a malaria-risk area and we'll calculate how many pills you need.
            </p>
            <div className="flow--small">
                <div className="flex breathe--xlarge">
                    <div className="timed-quantity__field">
                        <FieldWrapper id="form-field--number-of-days" label="Number of days">
                            <Input
                                id="form-field--number-of-days"
                                name="number_of_days"
                                type="number"
                                value={numberOfDays}
                                onChange={handleDayChange}
                                min={0}
                            />
                        </FieldWrapper>
                    </div>
                    <div className="timed-quantity__field">
                        <FieldWrapper id="form-field--number-of-pills" label="Number of pills">
                            <Input
                                id="form-field--number-of-pills"
                                name="number_of_pills"
                                type="number"
                                value={numberOfPills}
                                onChange={handlePillChange}
                                min={0}
                                isValid={isPillInputValid}
                            />
                        </FieldWrapper>
                    </div>
                </div>
                {noAmountErrorMessage}
                {maxPillErrorMessage}
            </div>
            {explanation ? (
                <HelperInline content={explanation} buttonText={{ closed: 'How is this calculated', open: 'Close information' }} />
            ) : null}
        </div>
    );
};

TimedQuantity.defaultProps = {
    explanation: undefined,
};

TimedQuantity.propTypes = {
    /** The modifiers for the number of pills per day etc. */
    modifiers: PropTypes.oneOfType([PropTypes.shape({}), timedModifiersShape]).isRequired,
    /** HTML string about how the number of pills is calculated by day. */
    explanation: PropTypes.string,
    /** Information about the pills. */
    quantity: quantityShape.isRequired,
    /** Function to send the quantity information to. */
    onChange: PropTypes.func.isRequired,
};

export default TimedQuantity;
