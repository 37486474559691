import React from 'react';
import PropTypes from 'prop-types';

import BaseIcon from '../../_ui/_blocks/Icons/BaseIcon';
import IconStatusWarning from '../../_ui/_blocks/Icons/Status/IconStatusWarning';

const Error = ({ className, children }) => (
    <p className={`form-error ${className}`}>
        <BaseIcon>
            <IconStatusWarning />
        </BaseIcon>
        {children ? <span dangerouslySetInnerHTML={{ __html: children }} /> : <span>This field is required</span>}
    </p>
);

Error.defaultProps = {
    className: '',
    children: undefined,
};

Error.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
};

export default Error;
