import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import FooterPopularConditions from '../Navigation/CraftMenuTypes/FooterPopularConditions/FooterPopularConditions';
import FooterUsefulLinks from '../Navigation/CraftMenuTypes/FooterUsefulLinks/FooterUsefulLinks';
import FooterOurPolicies from '../Navigation/CraftMenuTypes/FooterOurPolicies/FooterOurPolicies';

import CompanyDetails from './CompanyDetails';

const Footer = (): JSX.Element => {
    const data = useStaticQuery(graphql`
        {
            craftcms {
                sitewide: globalSet(handle: "sitewideContent") {
                    ... on CraftCMS_sitewideContent_GlobalSet {
                        companyInformation
                    }
                }
            }
        }
    `);

    const { sitewide } = data.craftcms;

    return (
        <footer className="site-footer wrapper--medium">
            <div className="container flow--large">
                <a href="/" title="Go home" className="site-footer__logo">
                    <img src="/images/logo--white.svg" alt="The Independent Pharmacy" loading="lazy" height="42px" />
                </a>
                <section>
                    <div className="grid-x grid-margin-y grid-margin-x">
                        <div className="cell medium-6 large-3">
                            <CompanyDetails />
                        </div>
                        <div className="cell medium-6 large-3">
                            <FooterPopularConditions />
                        </div>
                        <div className="cell medium-6 large-3">
                            <FooterUsefulLinks />
                        </div>
                        <div className="cell medium-6 large-3">
                            <FooterOurPolicies />
                        </div>
                    </div>
                </section>
                {sitewide.companyInformation ? (
                    <section>
                        <div className="text-smallprint font-style--italic">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: sitewide.companyInformation,
                                }}
                            />
                        </div>
                    </section>
                ) : null}
            </div>
        </footer>
    );
};

export default Footer;
