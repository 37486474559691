import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

import Header from '@components/Navigation/Header';
import Basket from '@components/Basket/Basket';
import SidebarNavigation from '@components/Navigation/CraftMenuTypes/SidebarNavigation/SidebarNavigation';
import AccountNavigation from '@components/Navigation/AccountNavigation';
import loadable from '@loadable/component';
import { AccountNavigationProvider } from '@data/context/accountContext/accountNavigationContext';
import Confirmation from '@components/_ui/Confirmation/Confirmation';
import DrawerNavigation from '../../components/_new_components/DrawerNavigation/DrawerNavigation';
import SessionActivity from '../../components/Authentication/SessionActivity/SessionActivity';
import BasketBubble from '../../components/Basket/BasketBubble/BasketBubble';

const SearchOverlay = loadable(() => import('@components/Search/SearchOverlay/SearchOverlay'));
const FontEmbed = loadable(() => import('@components/_ui/FontEmbed'));
const CookiesNotification = loadable(() => import('@components/CookiesNotification'));

/**
 * Account Layout
 * Global providers sit in the account.jsx page for this. We've had to lock this behind auth.
 * So our routing means these are private and need to be stored at a higher spot when this is rendered at route level
 */
const AccountLayout = ({ children }) => (
    <Fragment>
        <Helmet>
            <title>Account</title>
        </Helmet>
        <FontEmbed />
        <AccountNavigationProvider>
            <Header enableMainNavigation={false} className="header--account" />
            <div className="acc-wrapper">
                <AccountNavigation />
                <main className="acc-main">{children}</main>
            </div>
            <Basket />
            <BasketBubble />
            <DrawerNavigation />
            <SidebarNavigation />
            <SearchOverlay />
            <Confirmation />
            <CookiesNotification />
        </AccountNavigationProvider>
        <SessionActivity />
    </Fragment>
);

AccountLayout.propTypes = {
    /** The page content components */
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element), PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

export default AccountLayout;
