import getQueryString from '../../../../helpers/getQueryString';
import { ProductVariant } from '../../../../types/api/products/Product';

type ReturnType = ProductVariant | null;

const getDefaultVariant = (variants: ProductVariant[]): ReturnType => {
    if (!variants) {
        return null;
    }

    let filteredVariants = variants;

    // If the variants should be filtered by stock.
    if (getQueryString('fbs') !== '0') {
        // Filter variants by ones with stock only. If none have stock, just loop through variants.
        const variantsWithStock = variants.filter((variant) => variant.quantities.length > 0);
        if (variantsWithStock.length) {
            filteredVariants = variantsWithStock;
        }
    }

    const urlVariantId = getQueryString('variant');

    if (urlVariantId) {
        const urlVariant = filteredVariants.find((variant) => variant.id.toString() === urlVariantId);
        if (urlVariant) {
            return urlVariant;
        }
    }

    const urlDefaultVariant = getQueryString('default');

    if (urlDefaultVariant) {
        const urlVariant = filteredVariants.find((variant) => variant.url === urlDefaultVariant);
        if (urlVariant) return urlVariant;
    }

    return filteredVariants.find((variant) => variant.default) || filteredVariants[0];
};

export default getDefaultVariant;
