import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';

import formatQuantityLabel from '../../Treatment/helpers/formatQuantityLabel';
import useTreatmentImage from '../../../data/staticQueryHooks/useTreatmentImage';
import Notification from '../../_ui/Notification';

/**
 * Shows the treatment image, name and label with a change button available if function is passed in. Can
 * display an information notification if the price break is out of date.
 * @param {object} props
 * @returns {ReactElement}
 */
const PriceBreak = ({ treatment, previousTreatment, onClick, disableNotifications }) => {
    // Gets the treatment image url.
    const { getTreatmentImage } = useTreatmentImage();
    const treatmentImage = getTreatmentImage(get(treatment, 'reference') || '') || '/favicon.ico';

    // If the labels have changed between the refill treatment and the latest treatment data.
    const hasLabelChanged = formatQuantityLabel(previousTreatment) !== formatQuantityLabel(treatment);

    return (
        <div className="flow">
            <div className="flex flex--align-center breathe--large">
                <img className="image--thumb" src={treatmentImage} alt={get(treatment, 'treatment')} />
                <div className="flow--xsmall flex--grow">
                    <span className="text-heading">{get(treatment, 'treatment')}</span>
                    <div className="flex flex--space-between">
                        <span className="text-metadata text-color--slate">{formatQuantityLabel(treatment)}</span>
                        {onClick ? (
                            <button type="button" className="text-link--account" onClick={onClick} title="Change this treatment variation">
                                Change
                            </button>
                        ) : null}
                    </div>
                </div>
            </div>
            {/* If the label has changed and the item is not out of stock or quantity is not available. */}
            {hasLabelChanged && disableNotifications === false ? (
                <Notification
                    className="flow--small notification--small"
                    type="info"
                    heading="This product has been updated since your last order"
                >
                    <p className="text-metadata">Please double check the pack size and price before confirming your refill.</p>
                </Notification>
            ) : null}
        </div>
    );
};

PriceBreak.defaultProps = {
    onClick: null,
    previousTreatment: null,
    disableNotifications: false,
};

PriceBreak.propTypes = {
    /**
     * The treatment to show to the user.
     */
    treatment: PropTypes.object.isRequired,
    /**
     * Function supplied to the "change" treatment button. If null, button isn't shown.
     */
    onClick: PropTypes.func,
    /**
     * The treatment previously ordered as saved in mongo, which could be out of date.
     */
    previousTreatment: PropTypes.object,
    /**
     * If any messages in this component should be shown to the user
     */
    disableNotifications: PropTypes.bool,
};

export default PriceBreak;
