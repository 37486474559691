import React from 'react';

const IconCheckmark = () => (
    <path
        fillRule="evenodd"
        d="M47.4028 0.522878C48.1714 1.24583 48.2022 2.44845 47.4716 3.20902L14.6209 37.409C14.2657 37.7788 13.7752 37.9917 13.2597 37.9998C12.7443 38.0079 12.2472 37.8105 11.8803 37.4521L0.571065 26.4028C-0.183521 25.6656 -0.191296 24.4626 0.553701 23.7159C1.2987 22.9692 2.51435 22.9615 3.26894 23.6987L13.1858 33.3875L44.6884 0.590988C45.4189 -0.169578 46.6342 -0.200072 47.4028 0.522878Z"
    />
);

export default IconCheckmark;
