interface Modifiers {
    before?: number;
    after?: number;
    daily?: number;
}

/**
 * Calculates the number of pills required per the number of days stated.
 */
export const calculateNumberOfPillsByDays = (days: number, modifiers: Modifiers) => {
    if (!days) return 0;

    const perDay = modifiers.daily || 1;

    let pills = 0;
    if (modifiers.before) pills += modifiers.before;
    if (modifiers.after) pills += modifiers.after;

    // Times the number of days given by the number of pills needed per day.
    pills += perDay * days;

    // We round up with ceil rather than down with floor because you can't have eg. 1.486... of a pill, so 2
    // pills would be needed.
    return Math.ceil(pills);
};

/**
 * Calculates the number of days the pills will work for.
 */
export const calculateNumberOfDaysByPills = (numberOfPillsRequested: number, modifiers: Modifiers) => {
    // The number of pills that need to be taken each day.
    const pillsNeededPerDay = modifiers.daily || 1;

    // Total number of pills needed for before and after the trip.
    const pillModifier = (modifiers.before || 0) + (modifiers.after || 0);

    // The number of pills that need to be taken for a one day trip.
    const pillsNeededForOneDayTrip = Math.ceil(pillsNeededPerDay + pillModifier);

    // If the number of pills requested is less than the amount needed for a one day trip,
    if (numberOfPillsRequested < pillsNeededForOneDayTrip) {
        return 0;
    }

    // If the number of pills requested is equal to the amount needed for a one day trip,
    if (numberOfPillsRequested === pillsNeededForOneDayTrip) {
        return 1;
    }

    // Remove the modifying pill count from the number of pills given to see how many pills are left.
    const remainingPillsForEachDay = numberOfPillsRequested - pillModifier;

    // We round down with floor for below code rather than up with ceil because a pill must cover a whole
    // day, not 0.562... of one.

    // If the number of pills needed per day is less than 1.
    if (pillsNeededPerDay < 1) {
        // See how many days a pill will last for.
        const howManyDaysAPillIsWorth = Math.floor(1 / pillsNeededPerDay);

        // Multiply the number of pill remaining by how many days 1 pill is worth.
        return remainingPillsForEachDay * howManyDaysAPillIsWorth;
    }

    // Calculate the number of days by dividing the number of pills remaining by the number of pills needed
    // per day.
    return Math.floor(remainingPillsForEachDay / pillsNeededPerDay);
};
