import React from 'react';

const IconOrder = () => (
    <path
        fillRule="evenodd"
        d="M37.7628 3.06734C38.1238 3.23694 38.4024 3.54333 38.537 3.9188L45.662 23.7938C45.9408 24.5714 45.5383 25.4281 44.7618 25.71L24.6902 32.9958C25.1561 33.5992 25.532 34.2756 25.7981 35.0049L40.7431 29.6383C41.0596 29.5246 41.4052 29.5206 41.7243 29.627L46.2243 31.127C47.0103 31.389 47.435 32.2384 47.173 33.0244C46.9111 33.8103 46.0616 34.235 45.2757 33.973L41.2683 32.6372L26.2361 38.0351C25.9984 41.963 22.7376 45.075 18.75 45.075C14.6079 45.075 11.25 41.7171 11.25 37.575C11.25 34.6699 12.9017 32.1506 15.3173 30.9049L7.2071 9.07501H2.25C1.42157 9.07501 0.75 8.40344 0.75 7.57501C0.75 6.74659 1.42157 6.07501 2.25 6.07501H8.25C8.87695 6.07501 9.43776 6.46493 9.6561 7.05262L18.2163 30.0937C18.3926 30.0813 18.5705 30.075 18.75 30.075C19.5474 30.075 20.3157 30.1994 21.0366 30.43L14.638 12.5812C14.3592 11.8035 14.7617 10.9469 15.5382 10.665L36.6132 3.01501C36.9881 2.87891 37.4018 2.89775 37.7628 3.06734ZM17.9662 12.9752L24.0788 30.0262L42.3338 23.3998L36.2212 6.34882L31.5321 8.05092L34.4692 16.4706C34.6478 16.9827 34.3857 17.5438 33.8784 17.7355L27.1284 20.2855C26.8772 20.3804 26.5984 20.3702 26.3547 20.2574C26.111 20.1445 25.923 19.9384 25.8329 19.6854L22.8168 11.2145L17.9662 12.9752ZM24.6968 10.5321L27.3751 18.0543L32.2597 16.209L29.652 8.73338L24.6968 10.5321ZM17.3499 33.2971C17.491 33.2876 17.6331 33.2579 17.7724 33.2061C17.8121 33.1913 17.8509 33.1751 17.8886 33.1574C18.1674 33.1033 18.4554 33.075 18.75 33.075C21.2353 33.075 23.25 35.0897 23.25 37.575C23.25 40.0603 21.2353 42.075 18.75 42.075C16.2647 42.075 14.25 40.0603 14.25 37.575C14.25 35.5785 15.5501 33.8857 17.3499 33.2971Z"
    />
);

export default IconOrder;
