import { useMemo } from 'react';

import { ProductsQuantity } from '../../../../../types/api/products/ProductsQuantity';

/**
 * Filters the price breaks for the ones to display.
 */
const useFilterPriceBreaks = (priceBreaks: ProductsQuantity[], currentStockLevel: number) => {
    const filteredQuantities = useMemo(() => {
        const maxDisplayed = 5;

        const filteredByStock = priceBreaks.filter(({ quantity }) => quantity <= currentStockLevel);

        // If there is no stock, Filter by featured flag, if there are featured price breaks, display those,
        // otherwise display the first 5.
        if (filteredByStock.length === 0) {
            const filterByFeatured = priceBreaks.filter(({ featured }) => featured);
            return filterByFeatured.length > 0 ? filterByFeatured : priceBreaks.slice(0, maxDisplayed);
        }

        // Filter by featured flag. If there are featured price breaks, display those, otherwise display the
        // first 5 with stock.
        const filterByFeatured = filteredByStock.filter(({ featured }) => featured);
        return filterByFeatured.length > 0 ? filterByFeatured : filteredByStock.slice(0, maxDisplayed);
    }, [priceBreaks, currentStockLevel]);

    return filteredQuantities;
};

export default useFilterPriceBreaks;
