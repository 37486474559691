import React, { useMemo } from 'react';

import { ProductsQuantity } from '../../../../types/api/products/ProductsQuantity';

import Typography from '../../common/Typography/Typography';
import Tag from '../../common/Tag/Tag';
import Button from '../../common/Buttons/Button/Button';
import PriceBreakListing from '../../PriceBreakListing/PriceBreakListing';
import useScrollToPriceComparison from './hooks/useScrollToPriceComparison';
import useFilterPriceBreaks from './hooks/useFilterPriceBreaks';

import * as styles from './TreatmentSelectorPriceBreaks.module.css';
import { calculateDifferenceAsPercentage } from '../../../../helpers/maths';

interface Props {
    priceBreaks: ProductsQuantity[];
    currentStockLevel: number;
    title?: string;
}

const TreatmentSelectorPriceBreaks = ({ title = 'Quantity & pricing', priceBreaks, currentStockLevel }: Props) => {
    const handleScrollToFullPricing = useScrollToPriceComparison();
    const filteredPriceBreaks = useFilterPriceBreaks(priceBreaks, currentStockLevel);

    const percentageSaved = useMemo(() => {
        if (priceBreaks.length < 2) {
            return 0;
        }

        const largestPriceBreak = priceBreaks[priceBreaks.length - 1];
        const smallestPriceBreak = priceBreaks[0];

        const percentage = calculateDifferenceAsPercentage(largestPriceBreak.unit_cost, smallestPriceBreak.unit_cost);

        return Math.floor(percentage);
    }, [priceBreaks]);

    return (
        <div className="space-y-100">
            <div className="flex gap-100 justify-between items-center">
                <Typography as="h5" variant="label">
                    {title}
                </Typography>
                <div>
                    {percentageSaved ? (
                        <Tag size="medium" color="info">
                            Save up to {percentageSaved}%
                        </Tag>
                    ) : null}
                </div>
            </div>
            <PriceBreakListing priceBreaks={filteredPriceBreaks} />
            {handleScrollToFullPricing ? (
                <div className="text-right">
                    <Button
                        variant="none"
                        title="View all available quantities"
                        onClick={handleScrollToFullPricing}
                        size="none"
                        className={styles.link}
                    >
                        <Typography variant="metadata" color="accent">
                            View all available quantities
                        </Typography>
                    </Button>
                </div>
            ) : null}
        </div>
    );
};

export default TreatmentSelectorPriceBreaks;
