import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

const ImagePreload = ({ src, renderImage, alt, ...rest }) => (
    <Fragment>
        <Helmet>
            <link rel="preload" href={src} as="image" />
        </Helmet>
        {renderImage && <img src={src} alt={alt} {...rest} />}
    </Fragment>
);

ImagePreload.defaultProps = {
    renderImage: true,
};

ImagePreload.propTypes = {
    renderImage: PropTypes.bool,
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
};

export default ImagePreload;
