import React from 'react';
import loadable from '@loadable/component';

import { useAssessmentContext } from '@data/context/assessmentContext/assessmentContext';

import Overlay from '../_ui/_blocks/Overlay';

const Assessment = loadable(() => import('./Assessment'));

/**
 * Component for hosting the assessment files. Only should lazy load in the assessment files in
 * once an assessment has been clicked.
 */
const AssessmentWrapper = () => {
    const { treatmentName } = useAssessmentContext();

    return (
        <Overlay className="flex flex--align-center flex--justify-center" show={!!treatmentName}>
            <Assessment />
        </Overlay>
    );
};

export default AssessmentWrapper;
