import getQueryString from '../../../../../../helpers/getQueryString';
import stringToInteger from './stringToInteger';

const getDefaultTimedQuantity = () => {
    const urlDefaultQuantity = getQueryString('quantity');

    if (urlDefaultQuantity) {
        return stringToInteger(urlDefaultQuantity);
    }

    return '';
};

export default getDefaultTimedQuantity;
