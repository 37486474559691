import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import CraftMenuLink from '../../CraftMenuLink/CraftMenuLink';

/** Useful links shown in the footer */
const FooterUsefulLinks = () => {
    const data = useStaticQuery(graphql`
        {
            craftcms {
                useful: nodes(navHandle: "footerUsefulLinks", level: 1) {
                    ...footerLinkShape
                }
            }
        }
    `);

    const { useful } = data.craftcms;

    return useful.length ? (
        <div className="flow--small">
            <span className="text-label">Useful Links</span>
            <ul className="link-list">
                {useful.map((link, index) => (
                    <li key={index}>
                        <CraftMenuLink menuNode={link}>{link.title}</CraftMenuLink>
                    </li>
                ))}
            </ul>
        </div>
    ) : null;
};

export default FooterUsefulLinks;
