import React, { createContext, useState, useContext, useMemo, useEffect } from 'react';
import uniqBy from 'lodash.uniqby';

import useAlgoliaSearch from '../../hooks/useAlgoliaSearch';
import { useNavigationCloseHook } from '../../hooks/useNavigationCloseHook';
import useThrottledEffect from '../../hooks/useThrottledEffect';

const SearchContext = createContext();

/**
 * HELPER
 * Specifc to our algolia implementation this retrieves the algolia listing
 * for treatments and produces conditions from it.
 * @param {arrayOfAlgoliaHits} algoliaTreatments
 */
const selectConditionsFromTreatments = (algoliaTreatments) => {
    let conditions;

    if (algoliaTreatments) {
        conditions = [];
        algoliaTreatments.forEach((t) => {
            conditions.push({
                condition: t.condition,
                condition_url: t.condition_url,
                condition_description: t.condition_description,
            });

            if (t.secondaryConditions) {
                t.secondaryConditions.forEach((c) => {
                    conditions.push({
                        condition: c.condition,
                        condition_url: c.condition_url,
                        condition_description: c.condition_description,
                    });
                });
            }
        });
    }

    return conditions && uniqBy(conditions, (d) => d.condition);
};

/**
 * This provider handles all of our search state. Wrapping a new provider will create a new scoped-state.
 * Meaning you can have multiple individual searches on a page. Useful for in page results vs overlay results.
 */
export const SearchProvider = ({ ...props }) => {
    const [searchTerm, setSearchTerm] = useState(() => {
        if (typeof window === 'undefined') return '';
        return new URLSearchParams(window.location.search).get('searchTerm') || '';
    });
    const [showOverlay, setShowOverlay] = useState(false);
    const [searchBarIsOpen, setSearchBarIsOpen] = useState(false);

    useNavigationCloseHook('search', () => setSearchBarIsOpen(false));

    // @see https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#onRouteUpdate
    // When url changes, update the search term if the user is on the search page and there is a search term
    // in the URL.
    useEffect(() => {
        const handleNavigationChange = (event) => {
            const { pathname, search } = event.detail.location;
            const params = new URLSearchParams(search);

            // If we're on the search page and there's a search term.
            if (pathname.includes('search-results') && params.get('searchTerm')) {
                setSearchTerm(params.get('searchTerm'));
                return;
            }

            setSearchTerm('');
        };

        window.addEventListener('routeUpdate', handleNavigationChange);
        return () => window.removeEventListener('routeUpdate', handleNavigationChange);
    }, [setSearchTerm]);

    /** Invoke our algolia search hook to listen to our search terms */
    const [data] = useAlgoliaSearch(
        searchTerm,
        `${'live_PRODUCTS'}`,
        {},
        {
            attributesToSnippet: 'description:20',
            hitsPerPage: 100,
        }
    );

    // Maintains search term in state with the one stored in the URL.
    useThrottledEffect(
        () => {
            if (typeof window === 'undefined') return;
            const params = new URLSearchParams(window.location.search);

            if (params.get('searchTerm') === searchTerm) return;

            if (searchTerm) {
                params.set('searchTerm', searchTerm);
            } else {
                params.delete('searchTerm');
            }

            let stringParams = params.toString();
            stringParams = stringParams ? `?${stringParams}` : '';

            window.history.replaceState(null, '', stringParams);
        },
        [searchTerm],
        1000
    );

    /** Listen to the searchTerm * */
    useEffect(() => {
        /** Close the overlay if we hit 0 length */
        if (searchTerm.length === 0) {
            setShowOverlay(false);
        }

        /** Reset the current page if searchTerm Changes */
    }, [searchTerm]);

    /** Get the treatments and the conditions. The conditions are based on the linked conditions on the treatments. */
    const treatments = data.algoliaResponse ? data.algoliaResponse.hits : undefined;
    const conditions = useMemo(() => selectConditionsFromTreatments(treatments), [treatments]);

    // TODO: We need to add a use memo to this to prevent re-renders (contextValueRefactor, Ticket: 862jfuy9w).
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    const value = {
        isLoading: data.isLoading,

        searchTerm,
        setSearchTerm,

        treatments,
        conditions,

        searchBarIsOpen,
        setSearchBarIsOpen,

        showOverlay,
        setShowOverlay,
    };

    return <SearchContext.Provider {...props} value={value} />;
};

export const SearchConsumer = ({ ...props }) => <SearchContext.Consumer {...props} />;

/**
 * Hook to be used in components that will allow us access to the values passed into the search provider above.
 * const { searchTerm } = useSearchContext()
 */
export const useSearchContext = () => {
    const context = useContext(SearchContext);

    if (context === undefined) {
        throw new Error('useSearchContext must be used within a SearchProvider');
    }
    return context;
};
