/* eslint-disable react/forbid-prop-types */
import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import BaseIcon from '@components/_ui/_blocks/Icons/BaseIcon';
import IconChevronDown from '@components/_ui/_blocks/Icons/Navigation/IconChevronDown';
import MainNavigationDropdown from './MainNavigationDropdown/MainNavigationDropdown';
import Megamenu from './Megamenu/Megamenu';
import CraftMenuLink from '../../CraftMenuLink/CraftMenuLink';

const MainNavigation = () => {
    const data = useStaticQuery(graphql`
        {
            craftcms {
                nodes: nodes(navHandle: "mainNavigation", level: 1) {
                    ...mainNavigation
                }
            }
            allCondition {
                nodes {
                    treatmentCount
                    computed {
                        relatedTreatmentCount
                    }
                    entry {
                        slug
                    }
                }
            }
        }
    `);

    const {
        allCondition,
        craftcms: { nodes },
    } = data;

    const conditions = allCondition.nodes.map((n) => ({
        ...n.entry,
        treatmentCount: n.treatmentCount,
        relatedTreatmentCount: n.computed.relatedTreatmentCount,
    })); // remap nodes.entry to just entry

    return (
        <nav className="nav-main" aria-label="Top Navigation">
            <ul className="nav-main__menu">
                {nodes &&
                    nodes.map((node) => {
                        const hasMegamenu =
                            node.children && node.children.length && node.classes && node.classes.indexOf('_has-megamenu') > -1;
                        const hasDropdown = node.children && node.children.length && !hasMegamenu; // only show this if we have children and no mega menu

                        return (
                            <li
                                className={`nav-main__item ${hasMegamenu ? '_has-megamenu' : ''} ${hasDropdown ? '_has-dropdown' : ''}`}
                                key={node.title}
                            >
                                {hasDropdown ? (
                                    <Fragment>
                                        <CraftMenuLink menuNode={node} className="nav-main__link" activeClassName="_is-active">
                                            <span>{node.title}</span>
                                            <BaseIcon className="icon--xxsmall">
                                                <IconChevronDown />
                                            </BaseIcon>
                                        </CraftMenuLink>
                                        <MainNavigationDropdown node={node} />
                                    </Fragment>
                                ) : null}
                                {hasMegamenu ? <Megamenu node={node} conditions={conditions} /> : null}
                                {!hasDropdown && !hasMegamenu && (
                                    <CraftMenuLink menuNode={node} className="nav-main__link" activeClassName="_is-active">
                                        <span>{node.title}</span>
                                    </CraftMenuLink>
                                )}
                            </li>
                        );
                    })}
            </ul>
        </nav>
    );
};

export default MainNavigation;
