/* eslint-disable react/button-has-type */
import React from 'react';
import clx from 'classnames';

import * as styles from '../styles/Button.module.css';

export enum Variants {
    primary = 'primary',
    secondary = 'secondary',
    bordered = 'bordered',
    ghost = 'ghost',
    none = 'reset',
}

export enum Sizes {
    small = 'small',
    medium = 'medium',
    large = 'large',
    none = '',
}

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    children: React.ReactNode;
    variant?: keyof typeof Variants;
    size?: keyof typeof Sizes;
    type?: 'button' | 'submit' | 'reset';
    className?: string; // Should only be used for layout purposes e.g. flex or inline-block.
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    title: string;
}

const Button = ({ children, type = 'button', variant = 'primary', size = 'medium', className, disabled, onClick, ...rest }: Props) => {
    const variantCss = disabled ? styles.disabled : styles[Variants[variant]];

    const classNames = clx(styles.button, variantCss, styles[Sizes[size]], className);

    return (
        <button type={type} className={classNames} onClick={onClick} disabled={disabled} {...rest}>
            {children}
        </button>
    );
};

export default Button;
