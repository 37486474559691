/* eslint-disable quote-props */
import React from 'react';
import useTypography, { UseTypographyOptions } from './hooks/useTypography';

interface Props extends UseTypographyOptions, Omit<React.AllHTMLAttributes<HTMLOrSVGElement>, 'color' | 'as'> {
    children: React.ReactNode;
}

const Typography = ({ as: Tag = 'span', variant, color, children, className, fontSize, accent, lineHeight, weight, ...props }: Props) => {
    const cn = useTypography({
        variant,
        color,
        className,
        fontSize,
        accent,
        lineHeight,
        weight,
    });

    return (
        <Tag {...props} className={cn}>
            {children}
        </Tag>
    );
};

export default Typography;
