import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import CraftMenuLink from '../../CraftMenuLink/CraftMenuLink';

/** Popular conditions menu that displays in the footer. */
const FooterPopularConditions = () => {
    const data = useStaticQuery(graphql`
        {
            craftcms {
                popular: nodes(navHandle: "footerPopularConditions", level: 1) {
                    ...footerLinkShape
                }
            }
        }
    `);

    const { popular } = data.craftcms;

    return popular.length ? (
        <div className="flow--small">
            <span className="text-label">Popular Conditions</span>
            <ul className="link-list">
                {popular.map((link, index) => (
                    <li key={index}>
                        <CraftMenuLink menuNode={link}>{link.title}</CraftMenuLink>
                    </li>
                ))}
            </ul>
        </div>
    ) : null;
};

export default FooterPopularConditions;
