import { affiliateInScope } from '../../../../../affiliate/helpers/affiliateInScope';

import ProductTypes from '../../../../../../types/api/products/ProductTypes';
import { Product } from '../../../../../../types/api/products/Product';

export interface FormatProductsReturnType {
    name: string;
    id: number;
    category: string;
    variant: string;
    dimension2: 'Logged in' | 'Not Logged in';
    dimension3: ProductTypes;
    dimension4: 'Default Variant' | 'Not Default Variant';
    dimension15: string | null;
}

const formatProducts = (treatment: Product, isLoggedIn: boolean, affiliateCode: string): FormatProductsReturnType[] => {
    const { variants, condition_name, name: treatmentname } = treatment;

    return variants.map((variant) => {
        const { default: defaultVariant, type, name, id } = variant; // eslint-disable-line object-curly-newline

        const isDefault = defaultVariant ? 'Default Variant' : 'Not Default Variant';
        const loggedIn = isLoggedIn ? 'Logged in' : 'Not Logged in';

        return {
            name: treatmentname,
            id,
            category: condition_name || 'General Health',
            variant: name,
            dimension2: loggedIn,
            dimension3: type.toUpperCase() as ProductTypes, // PRODUCT, PMED, ODONLY
            dimension4: isDefault,

            dimension15: affiliateInScope(id) ? affiliateCode : null, // If there is an affiliate code applied.
        };
    });
};

export default formatProducts;
