import React from 'react';
import PropTypes from 'prop-types';

// import findIcon from '@components/_ui/_blocks/Icons/helpers/findIcon';

import BaseIcon from '@components/_ui/_blocks/Icons/BaseIcon';
import IconCross from '@components/_ui/_blocks/Icons/Navigation/IconCross';
import IconStatusWarning from './Icons/Status/IconStatusWarning';

const Flash = ({ className, type, message, onClose }) => (
    <div className={`flash flash--${type} ${className}`}>
        <div className="breathe vertical-align--middle">
            <BaseIcon className="flash__icon flash__icon--inherit">
                {/* TODO: findIcon is causing a preact error in this component, think it's to do with loadable returning undefined  */}
                {/* {findIcon(`icon-${type}`)} */}
                <IconStatusWarning />
            </BaseIcon>
            <p className="text-paragraph flash__message">{message}</p>
        </div>
        {onClose ? (
            <button type="button" className="button--transparent" onClick={onClose} title="Close message notification">
                <BaseIcon className="display--block flash__close">
                    <IconCross />
                </BaseIcon>
            </button>
        ) : null}
    </div>
);

Flash.defaultProps = {
    className: '',
    type: 'warning',
    onClose: undefined,
};

Flash.propTypes = {
    /** CSS Class that gets applied to the outer div */
    className: PropTypes.string,
    /** Icon Type */
    type: PropTypes.string,
    /** Flash Message */
    message: PropTypes.string.isRequired,
    /** Function to trigger on hitting the close icon */
    onClose: PropTypes.func,
};

export default Flash;
