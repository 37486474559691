import React, { createContext, useState, useContext, useMemo
} from 'react';
import get from 'lodash.get';


// eslint-disable-next-line import/no-cycle
import { useConsultationLauncherContext } from './consultationLauncherContext';
import useDataApiEffect from '../../../hooks/useDataApiEffect';
import productsService from '../../services/products.service';

import ConsultationReorderConfirm from '../../../components/Consultation/ConsultationReorderConfirm/ConsultationReorderConfirm';
import Overlay from '../../../components/_ui/_blocks/Overlay';
import ModalErrorBoundary from '../../../components/ErrorBoundaries/ModalErrorBoundary';

interface TreatmentToReorder {
    reference: number;
    treatment: string;
    quantity: number;
}

interface ContextReturnValues {
    setTreatmentToReorder: (treatment: TreatmentToReorder | null) => void;
}

// Start of the consultation treatment context.
const ConsultationReorderContext = createContext<unknown>({});

/**
 * The provider for the consultation treatment.
 * @returns React elements
 */
export const ConsultationReorderProvider = (({ children }: { children: React.ReactNode }) => {
    const [treatmentToReorder, setTreatmentToReorder] = useState<TreatmentToReorder | null>(null);

    const { getConsultationPanels, loadingPanels } = useConsultationLauncherContext();

    const [{ isLoading: loadingTreatment, data, error: treatmentError }, /* */, reset] = useDataApiEffect(
        treatmentToReorder ? () => productsService.getProduct(treatmentToReorder.reference) : null,
        [treatmentToReorder]
    );

    const handleClose = () => {
        reset({ isLoading: true }); // Need to set the loading state back to true on close.
        setTreatmentToReorder(null);
    };

    const treatment = useMemo(() => (get(data, 'data') || null), [data]);

    // TODO: Store values in a useMemo to prevent rerenders.
    const value = { // eslint-disable-line react/jsx-no-constructed-context-values
        setTreatmentToReorder
    };

    return (
        <ConsultationReorderContext.Provider value={value}>
            {treatmentToReorder ? (
                <Overlay
                    show={!!treatmentToReorder}
                    fixedToViewport
                    className="overlay--children-centered"
                    onHide={handleClose}
                >
                    <ModalErrorBoundary onClose={handleClose}>
                        <ConsultationReorderConfirm
                            loadingTreatment={loadingTreatment}
                            treatmentError={treatmentError}
                            getConsultationPanels={getConsultationPanels}
                            loadingPanels={loadingPanels}
                            treatment={treatment}
                            treatmentToReorder={treatmentToReorder}
                            onClose={handleClose}
                        />
                    </ModalErrorBoundary>
                </Overlay>
            ) : null}
            {children}
        </ConsultationReorderContext.Provider>
    );
});

/**
 * Hook to be used in components that will allow us access to the values passed into the search provider
 * above.
 * @param {*} showContextError - sometimes we want to use this but fail silently, such as we need the context on a sign up form, as its required whilst being in the consultation signup
 */
export const useConsultationReorderContext = (showContextError = true) => {
    const context = useContext<ContextReturnValues>(ConsultationReorderContext as React.Context<ContextReturnValues>);

    if (context === undefined && showContextError) {
        throw new Error('useConsultationReorderContext must be used within a ConsultationReorderProvider');
    }
    return context;
};
