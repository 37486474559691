import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import BusyIcon from '@components/_ui/_blocks/BusyIcon';

const Loading = ({ isLoading, type, iconClassName, children, message }) => (
    <CSSTransition timeout={0} in appear mountOnEnter unmountOnExit>
        {isLoading ? (
            <div data-testid="loading-icon" className={`loading flow--small loading--${type} ${iconClassName}`}>
                <BusyIcon animate />
                {message ? <span className="text-label display--block text-align--center text-color--slate">{message}</span> : null}
            </div>
        ) : (
            // Fragment is required as multiple children can be passed in.
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <Fragment>{children}</Fragment>
        )}
    </CSSTransition>
);

export default Loading;

Loading.defaultProps = {
    children: '',
    type: '',
    iconClassName: '',
    message: null,
};

Loading.propTypes = {
    /** Boolean for if the component is loading to display the load icon. */
    isLoading: PropTypes.bool.isRequired,
    /** If it is covering content or inline with content. */
    type: PropTypes.oneOf(['full-screen', '', 'inline', 'small']),
    /** Classes that can be added to the icon wrapper. */
    iconClassName: PropTypes.string,
    /** content to display when loaded. */
    children: PropTypes.node,
    /** optional text message displayed below the icon. */
    message: PropTypes.node,
};
