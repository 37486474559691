import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { quantityShape } from '../../../propTypes/treatmentShape';
import formatQuantityLabel from '../../../helpers/formatQuantityLabel';
import { ORDER_TYPES } from '../../../../../constants';

import SelectInput from '../../../../Forms/Inputs/SelectInput/SelectInput';
import FieldWrapper from '../../../../Forms/FieldWrapper/FieldWrapper';
import getQueryString from '../../../../../helpers/getQueryString';

const RegularQuantity = ({ selectedQuantity, selectedVariantType, hideLabel, quantities, onChange }) => {
    /**
     * Gets the default variant by its "default" boolean attribute, else, by the first item in the
     * array. Set the value when a default is found.
     */
    useEffect(() => {
        if (quantities && !selectedQuantity) {
            const urlDefaultQuantity = getQueryString('quantity');

            if (urlDefaultQuantity) {
                const urlVariant = quantities.find((quantity) => quantity.quantity.toString() === urlDefaultQuantity);
                if (urlVariant) {
                    onChange(urlVariant);
                    return;
                }
            }

            onChange(quantities.find((variant) => variant.default) || quantities[0]);
        }
    }, [quantities, selectedQuantity]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Sorts the options into correct order by quantity, smallest to biggest.
     */
    const options = useMemo(
        () => quantities.sort((a, b) => a.quantity - b.quantity).map((q) => ({ value: q.quantity, text: formatQuantityLabel(q) })),
        [quantities]
    );

    const handleOnChange = (quantity) => {
        const foundQuantity = quantities.find((q) => q.quantity.toString() === quantity);
        onChange(foundQuantity);
    };

    const isOdonly = selectedVariantType.toUpperCase() === ORDER_TYPES.odonly;

    /**
     * If there is only one price break, stop the user from being able to select a quantity.
     */
    if (options.length === 1 && isOdonly) {
        return (
            <div className="treatment-selector__quantity-single-pricebreak flow">
                <p className="text-label">Prescribed Quantity</p>
                <p>{options[0].text}</p>
            </div>
        );
    }

    let label = '';

    if (hideLabel === false) {
        label = isOdonly ? 'Quantity options' : 'Quantity';
    }

    return (
        <FieldWrapper id="treatment-quantity" label={label}>
            <SelectInput
                id="treatment-quantity"
                name="quantity"
                onChange={(e) => handleOnChange(e.target.value)}
                onBlur={(e) => handleOnChange(e.target.value)}
                placeholder="Select quantity..."
                value={selectedQuantity || ''}
                options={options}
            />
        </FieldWrapper>
    );
};

RegularQuantity.defaultProps = {
    selectedQuantity: undefined,
    hideLabel: false,
};

RegularQuantity.propTypes = {
    selectedQuantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** The type of treatment variant e.g. ODONLY */
    selectedVariantType: PropTypes.oneOf(Object.keys(ORDER_TYPES).map((type) => ORDER_TYPES[type])).isRequired,
    quantities: PropTypes.arrayOf(quantityShape).isRequired,
    hideLabel: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
};

export default RegularQuantity;
