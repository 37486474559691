import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import get from 'lodash.get';

import useTotalTreatmentAndConditionsCount from '../../../../../data/staticQueryHooks/useTotalTreatmentAndConditionCount';

import BaseIcon from '../../../../_ui/_blocks/Icons/BaseIcon';
import IconChevronRight from '../../../../_ui/_blocks/Icons/Navigation/IconChevronRight';
import CraftMenuLink from '../../../CraftMenuLink/CraftMenuLink';

const MegamenuConditions = ({ nodes, conditions }) => {
    const { treatmentCount, conditionCount } = useTotalTreatmentAndConditionsCount();

    const timeout = useRef();

    const handleButtonClick = () => {
        document.body.classList.add('_js-nav-megamenu-cancel');
        timeout.current = setTimeout(() => {
            document.body.classList.remove('_js-nav-megamenu-cancel');
        }, [500]);
    };

    useEffect(
        () => () => {
            // On unmount clear timeout
            if (timeout.current) {
                document.body.classList.remove('_js-nav-megamenu-cancel');
                clearTimeout(timeout.current);
            }
        },
        []
    );

    return (
        <div className="nav-megamenu grid-x">
            <div className="cell medium-7 nav-megamenu__section">
                <div className="grid-x grid-margin-x">
                    <div className="cell nav-megamenu__header">
                        <span className="text-title-three nav-megamenu__title">Popular Conditions</span>
                        <Link to="/conditions" className="button button--small button--shadow" onClick={handleButtonClick}>
                            View all
                        </Link>
                    </div>
                    {nodes.map((node) => {
                        const condition = conditions.find((c) => c.slug === node.element.slug);

                        return (
                            <div className="small-6 cell" key={node.id}>
                                <CraftMenuLink
                                    title={`See all ${node.title} Treatments`}
                                    menuNode={node}
                                    className={node.element ? 'nav-megamenu__condition' : null}
                                >
                                    <span className="text-label">
                                        {node.title}&nbsp;
                                        <span className="text-metadata text-color--slate">
                                            ({get(condition, 'relatedTreatmentCount', 0)})
                                        </span>
                                    </span>
                                    <BaseIcon className="nav-megamenu__chevron">
                                        <IconChevronRight />
                                    </BaseIcon>
                                </CraftMenuLink>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="cell medium-5 nav-megamenu__cta">
                <div className="flow--large">
                    <span className="text-title-five">
                        We offer <strong>{treatmentCount}</strong> over-the-counter and prescription medicines, for the treatment of{' '}
                        <strong>{conditionCount}</strong> conditions.
                    </span>
                    <span className="text-metadata text-color--slate">
                        We also provide expert advice on a range of common health issues.
                    </span>
                    <div className="text-center">
                        <Link to="/treatments" className="button button--primary button--shadow" onClick={handleButtonClick}>
                            Find your treatment
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

MegamenuConditions.defaultProps = {
    nodes: [],
    conditions: [],
};

MegamenuConditions.propTypes = {
    /** The navigation tree to be rendered */
    nodes: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            title: PropTypes.string,
            level: PropTypes.string,
            url: PropTypes.string,
            parent: PropTypes.shape({
                id: PropTypes.string,
            }),
        })
    ),
    conditions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            treatmentCount: PropTypes.number,
        })
    ),
};

export default MegamenuConditions;
