/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Fragment } from 'react';
import { CSSTransition } from 'react-transition-group';

import PropTypes from 'prop-types';

import BaseIcon from '@components/_ui/_blocks/Icons/BaseIcon';
import IconCross from '@components/_ui/_blocks/Icons/Navigation/IconCross';
import Overlay from '@components/_ui/_blocks/Overlay';
import useLockViewportScrolling from '@hooks/useLockViewportScrolling';

import useEscKey from '@hooks/useEscKey';

const Flyout = ({ isOpen, transitionName, className, enableHeader, disableOverlay, title, children, pinnedChildren, onClose }) => {
    useLockViewportScrolling(!!(isOpen && !disableOverlay));

    useEscKey(!disableOverlay ? onClose : null);

    return (
        <Fragment>
            {!disableOverlay ? (
                <Overlay className="overlay--children-fill" show={isOpen}>
                    <div role="button" onClick={onClose} />
                </Overlay>
            ) : null}

            <CSSTransition in={isOpen} mountOnEnter unmountOnExit classNames={transitionName} timeout={{ enter: 600, exit: 400 }}>
                <aside className={`flyout ${className}`}>
                    {enableHeader ? (
                        <header className="flyout__header">
                            <h3 className="flyout__title font-size--200 text-smallcaps">{title}</h3>
                            <button type="button" className="flyout__close-button button-transparent" onClick={onClose}>
                                <BaseIcon className="display--block">
                                    <IconCross />
                                </BaseIcon>
                            </button>
                        </header>
                    ) : null}
                    <div className="flyout__inner">{children}</div>
                    {pinnedChildren}
                </aside>
            </CSSTransition>
        </Fragment>
    );
};

Flyout.defaultProps = {
    isOpen: false,
    title: undefined,
    className: '',
    transitionName: '_a_flyout',
    children: undefined,
    pinnedChildren: undefined,
    enableHeader: true,
    disableOverlay: false,
    onClose: undefined,
};

Flyout.propTypes = {
    /** To determine whether the Flyout is open or not. */
    isOpen: PropTypes.bool,
    /** Applies a title to the Flyout */
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /** CSS Class name that is attached to the aside */
    className: PropTypes.string,
    /** Transition name that is attached to the CSSTransitionGroup */
    transitionName: PropTypes.string,
    /** Children that are rendered inside the Flyout. */
    children: PropTypes.node,
    /** Pinned children that are rendered inside the Flyout. */
    pinnedChildren: PropTypes.node,
    /** Function to onclose the Flyout. */
    onClose: PropTypes.func,
    /** Show or hide the default flyout header structure */
    enableHeader: PropTypes.bool,
    /** Show or hide the overlay layer when flyout is open */
    disableOverlay: PropTypes.bool,
};

export default Flyout;
