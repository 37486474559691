import React, { useCallback } from 'react';

import BaseIcon from '../../../_ui/_blocks/Icons/BaseIcon';
import IconLock from '../../../_ui/_blocks/Icons/IconLock';

export const telPattern = `^[0-9-+\\s()]*$`; // eslint-disable-line

interface InputProps {
    id?: string;
    name: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;

    type?: string;
    value?: string;
    placeholder?: string;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    disabled?: boolean;
    readOnly?: boolean;
    required?: boolean;
    pattern?: string;
    minLength?: number;
    maxLength?: number;
    min?: number;
    max?: number;
    step?: number;
    autoComplete?: string;
    autoFocus?: boolean;
    spellCheck?: boolean;
    isValid?: boolean;
    isLocked?: boolean;
    append?: React.ReactNode;
    className?: string;
    maximumWidth?: number;
    forceNumber?: boolean;
    dataLpignore?: boolean;
}

const Input = ({
    id,
    type = 'text',
    name,
    value,
    min,
    max,
    onChange,
    placeholder,
    autoComplete,
    isValid,
    disabled,
    isLocked,
    append,
    className,
    maximumWidth,
    forceNumber,
    dataLpignore,
    ...rest
}: InputProps): JSX.Element => {
    const patterns = {
        number: '[0-9]*',
        tel: telPattern,
    };

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const val = e.target.value.trim();

            /**
             * We hijack the number input here and make sure that the value cannot be more than the min or max
             * even if typed.
             */
            if (forceNumber && type === 'number') {
                if (typeof max !== 'undefined' && parseFloat(val) > max) {
                    e.target.value = max.toString();
                }
                if (typeof min !== 'undefined' && parseFloat(val) < min) {
                    e.target.value = min.toString();
                }
                if (val === '' || typeof val === 'undefined') {
                    e.target.value = '';
                }
            }

            onChange(e);
        },
        [min, max, type]
    ); // eslint-disable-line

    const pattern = ['number', 'tel'].includes(type) ? patterns[type as 'number' | 'tel'] : undefined;

    return (
        <div className="flex breathe position--relative">
            <input
                id={id && id}
                name={name}
                className={`form-field ${!isValid ? 'form-field--error' : ''} ${
                    isLocked || disabled ? 'form-field--read-only' : ''
                } ${className}`}
                type={type}
                value={value}
                style={{ maxWidth: maximumWidth }}
                onChange={handleChange}
                placeholder={placeholder}
                disabled={disabled}
                max={max}
                min={min}
                autoComplete={autoComplete}
                pattern={pattern}
                inputMode={type === 'number' ? 'decimal' : undefined}
                {...rest}
                data-lpignore={!!(disabled || isLocked) || dataLpignore}
            />
            {append || null}
            {isLocked ? (
                <BaseIcon className="form-field__icon">
                    <IconLock />
                </BaseIcon>
            ) : null}
        </div>
    );
};

export default Input;

Input.defaultProps = {
    id: undefined,
    type: 'text',
    placeholder: undefined,
    isValid: true,
    disabled: false,
    isLocked: false,
    append: undefined,
    autoComplete: undefined,
    value: '',
    max: undefined,
    min: undefined,
    maximumWidth: undefined,
    className: '',
    forceNumber: true,
    onBlur: null,
    onFocus: null,
    onKeyDown: null,
    onKeyUp: null,
    onKeyPress: null,
    readOnly: null,
    required: null,
    pattern: null,
    minLength: null,
    maxLength: null,
    step: null,
    autoFocus: null,
    spellCheck: null,
};
