/**
 * Converts a string to a number, removes any letters, and rounds number to the highest integer.
 */
const stringToInteger = (string: string, defaultReturn = '') => {
    const convertedNumber = parseFloat(string);

    if (Number.isNaN(convertedNumber)) {
        return defaultReturn;
    }

    return Math.ceil(convertedNumber);
};

export default stringToInteger;
