import { QUANTITY_TYPES } from '../../../../constants';

const getSelectedTreatment = (treatment, variantReference, quantity, hideQuantity = false) => {
    const data = {
        variant: null,
        quantity: null,
    };

    const variant = treatment.variants.find((v) => v.id.toString() === variantReference.toString());

    if (!variant) {
        return data;
    }

    data.variant = variant;

    const quantityType = variant.quantity_type.toUpperCase();

    let variantQuantity = null;

    if (quantityType === QUANTITY_TYPES.regular) {
        variantQuantity = variant.quantities.find((q) => q.quantity.toString() === quantity.toString());
    }

    if (quantityType === QUANTITY_TYPES.timed && variant.quantities.length) {
        [variantQuantity] = variant.quantities;
    }

    if (!variantQuantity) {
        return data;
    }

    const variantPrice = quantityType === QUANTITY_TYPES.regular ? variantQuantity.price : quantity * variantQuantity.unit_cost;

    return {
        variant,
        quantity:
            variantPrice && hideQuantity === false
                ? {
                      quantity,
                      price: variantPrice,
                      label: variantQuantity.label,
                      customer_label: variantQuantity.customer_label,
                  }
                : null,
    };
};

export default getSelectedTreatment;
