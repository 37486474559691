/* eslint-disable no-unreachable */
import React, { useMemo } from 'react';

import { useBasketContext } from '../../../../data/context/basketContext/basketContext';
import { genderSlugToReadable } from '../../../Forms/Inputs/SelectGender';
import isGenderSuitableForTreatment from '../helpers/isGenderSuitableForTreatment';
import formatBasketBlockedByTreatmentsNames from '../helpers/formatBasketBlockedByTreatmentsNames';

import Notification from '../../../_ui/Notification';
import OutOfStockQuantity from '../QuantitySelector/OutOfStockQuantity/OutOfStockQuantity';

const useTreatmentBlockedMessages = ({ treatment, selectedVariation, patientGender = null, conditionSlug }) => {
    const { basket, setBasketIsOpen } = useBasketContext();

    /**
     * Finds any blockers related to the current variant and an item in the basket.
     * Returns an array of error message ['string'].
     */
    const blockerMessages = useMemo(() => {
        if (!treatment || !selectedVariation.variant) return null;

        /**
         * Checks if the variant is out of stock.
         */
        if (!selectedVariation.variant.quantities.length) {
            return (
                <OutOfStockQuantity
                    treatment={selectedVariation.variant}
                    conditionName={treatment.condition_name}
                    conditionSlug={conditionSlug}
                />
            );
        }

        const treatmentGender = selectedVariation.variant.gender;
        if (!isGenderSuitableForTreatment(patientGender, treatmentGender)) {
            return (
                <Notification
                    type="warning"
                    heading={`Sorry, we are only able to provide this treatment for ${genderSlugToReadable(treatmentGender)} patients.`}
                    className="notification--small flow--small"
                >
                    <p className="text-metadata">
                        You have indicated that your biological sex is <strong>{genderSlugToReadable(patientGender)}</strong>. Please create
                        a separate account if this treatment is required for a different person to the individual registered on this
                        account. If you believe that there is an issue with the biological sex recorded on your account, please contact our
                        support team.
                    </p>
                </Notification>
            );
        }

        if (!basket || !basket.items) return null;

        // If the selected treatment is already in the basket.
        if (basket.items.find(({ reference }) => reference === selectedVariation.variant.id)) {
            return (
                <Notification
                    type="info"
                    heading="This treatment is already in your basket"
                    className="notification--small flow--small"
                >
                    <p className="text-metadata">You can checkout with this treatment or change the quantity from the basket.</p>
                    <button
                        type="button"
                        className="button button--primary"
                        aria-label="Open basket"
                        title="Open basket"
                        onClick={(e) => {
                            e.preventDefault();
                            setBasketIsOpen((s) => !s);
                        }}
                    >
                        Open basket
                    </button>
                </Notification>
            );
        }

        // const blockers = selectedVariation.variant.blocker_ids;
        // const itemsBlockingBasket = basket.items.filter(({ reference }) => blockers.includes(reference)).map(({ reference }) => reference);
        // If the current variant is blocked by a basket item, or the basket item is blocked by the current
        // variant.
        const itemsBlockingBasket = basket.items
            .filter((basketItem) => (
                basketItem.blocker_ids.includes(selectedVariation.variant.id) ||
                selectedVariation.variant.blocker_ids.includes(basketItem.reference)
            ))
            .map(({ product_name: productName }) => productName);

        // If there is a basket item stopping the treatment from being added to the basket
        if (itemsBlockingBasket.length) {
            const formattedProductNames = formatBasketBlockedByTreatmentsNames(itemsBlockingBasket);

            return (
                <Notification
                    type="info"
                    heading="This treatment cannot be added to your basket"
                    className="notification--small flow--small"
                >
                    <p className="text-metadata">
                        You cannot add this treatment to your basket as it already contains {formattedProductNames}. You can open your
                        basket below to amend your current products.
                    </p>
                    <button
                        type="button"
                        className="button button--primary"
                        aria-label="Open basket"
                        title="Open basket"
                        onClick={(e) => {
                            e.preventDefault();
                            setBasketIsOpen((s) => !s);
                        }}
                    >
                        Open basket
                    </button>
                </Notification>
            );
        }

        return null;
    }, [basket, treatment, selectedVariation.variant, patientGender]);

    return { blockerMessages };
};

export default useTreatmentBlockedMessages;
