import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import ImagePreload from '../_ui/_blocks/ImagePreload';

/**
 * A minimal header used for the website in the minimal layout component.
 * @param {object} props
 * @returns {ReactElement}
 */
const HeaderMinimal = ({ className }) => (
    <header className={`header ${className}`}>
        <div className="header__top header__top--minimal">
            <Link to="/" className="header__logo" title="The Independent Pharmacy homepage" aria-label="The Independent Pharmacy homepage">
                <ImagePreload src="/images/logo.svg" alt="The Independent Pharmacy" width="125" className="display--block" />
            </Link>
        </div>
    </header>
);

HeaderMinimal.defaultProps = {
    className: '',
};

HeaderMinimal.propTypes = {
    /**
     * Extra class names that can be added.
     */
    className: PropTypes.string,
};

export default HeaderMinimal;
