import React from 'react';

const IconHome = () => (
    <path
        fillRule="evenodd"
        d="M23.063 0.578697C23.6108 0.140434 24.3893 0.140434 24.9371 0.578697L32.25 6.42902V4C32.25 3.17157 32.9216 2.5 33.75 2.5H40.5C41.3284 2.5 42 3.17157 42 4V14.229L47.4371 18.5787C48.084 19.0962 48.1889 20.0401 47.6714 20.687C47.1538 21.3339 46.2099 21.4388 45.563 20.9213L42 18.0709V44.5C42 45.3284 41.3284 46 40.5 46H7.5C6.67157 46 6 45.3284 6 44.5V18.071L2.4371 20.9213C1.7902 21.4388 0.846266 21.3339 0.328752 20.687C-0.188762 20.0401 -0.0838802 19.0962 0.563013 18.5787L6.5509 13.7884C6.55937 13.7815 6.56792 13.7746 6.57655 13.7679L23.063 0.578697ZM9 15.671V43H16.5V28C16.5 27.5858 16.8358 27.25 17.25 27.25H30.75C31.1642 27.25 31.5 27.5858 31.5 28V43H39V15.6709L33.0494 10.9104C32.8818 10.8217 32.733 10.7022 32.6106 10.5594L24.0001 3.67094L9 15.671ZM35.25 8.82902L39 11.829V5.5H35.25V8.82902ZM30 43V28.75H18V43H30Z"
    />
);

export default IconHome;
