/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { Link } from 'gatsby';

import AccountDropdown from '../_ui/Account/AccountDropdown';
import ImagePreload from '../_ui/_blocks/ImagePreload';

const HeaderSparse = () => (
    <header className="header header--shadow">
        <div className="header__top header__top--no-padding-right">
            <Link to="/" className="header__logo">
                <ImagePreload src="/images/logo.svg" alt="The Independent Pharmacy" width="125" className="display--block" />
            </Link>
            <div className="header__buttons">
                <AccountDropdown />
            </div>
        </div>
    </header>
);

export default HeaderSparse;
