import React, { ComponentProps } from 'react';
import { Link, GatsbyLinkProps } from 'gatsby';
import clx from 'classnames';

import Button, { Variants, Sizes } from '../Button/Button';

import * as styles from '../styles/Button.module.css';

type CustomGatsbyLinkProps = Omit<GatsbyLinkProps<Record<string, unknown>>, 'ref'>;
type PickedButtonProps = Pick<ComponentProps<typeof Button>, 'variant' | 'size'>;

interface Props extends PickedButtonProps, CustomGatsbyLinkProps {
    children: React.ReactNode;
    title: string;
}

/**
 * Wrapper for using next/link and our button styles
 */
const ButtonLink = ({ children, to, title, size = 'medium', variant = 'primary', className, ...props }: Props) => {
    const classNames = clx('inline-block', styles.button, styles[Variants[variant]], styles[Sizes[size]], className);

    return (
        <Link to={to} title={title} className={classNames} {...props}>
            {children}
        </Link>
    );
};

export default ButtonLink;
