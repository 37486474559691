import React from 'react';

const IconArrowRight = () => (
    <path
        fillRule="evenodd"
        d="M22.9393 0.93934C23.5251 0.353553 24.4749 0.353553 25.0607 0.93934L47.0607 22.9393C47.6465 23.5251 47.6465 24.4749 47.0607 25.0607L25.0607 47.0607C24.4749 47.6465 23.5251 47.6465 22.9393 47.0607C22.3536 46.4749 22.3536 45.5251 22.9393 44.9393L42.3787 25.5H2C1.17157 25.5 0.5 24.8284 0.5 24C0.5 23.1716 1.17157 22.5 2 22.5H42.3787L22.9393 3.06066C22.3536 2.47487 22.3536 1.52513 22.9393 0.93934Z"
    />
);

export default IconArrowRight;
