import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import SocialIcons from '../_ui/_blocks/SocialIcons';
import ResponsiblePharmacist from './ResponsiblePharmacist';

interface OpeningTime {
    openingDay: string;
    openingHours: string;
}

interface Data {
    craftcms: {
        sitewide: {
            contactSupportEmail: string;
            contactTelephoneNumber: string;
            openingTimes: OpeningTime[];
        };
    };
}

const CompanyDetails = (): JSX.Element => {
    const data: Data = useStaticQuery(graphql`
        {
            craftcms {
                sitewide: globalSet(handle: "sitewideContent") {
                    ... on CraftCMS_sitewideContent_GlobalSet {
                        contactSupportEmail
                        contactTelephoneNumber
                        openingTimes {
                            ... on CraftCMS_openingTimes_openingTime_BlockType {
                                openingDay
                                openingHours
                            }
                        }
                    }
                }
            }
        }
    `);

    const { sitewide } = data.craftcms;

    return (
        <div className="flow--medium">
            <div className="flow--small">
                <span className="text-label">Email</span>
                <a
                    href={`mailto:${sitewide.contactSupportEmail}`}
                    className="text-metadata text-color--white display--block ellipsis"
                    title={`Email The Independent Pharmacy - ${sitewide.contactSupportEmail}`}
                >
                    {sitewide.contactSupportEmail}
                </a>
            </div>
            <div className="flow--small">
                <span className="text-label">Telephone</span>
                <a
                    href={`tel:${sitewide.contactTelephoneNumber.replace(/ /g, '')}`}
                    className="text-metadata display--block text-color--white"
                    title={`Call The Independent Pharmacy now on ${sitewide.contactTelephoneNumber}`}
                >
                    {sitewide.contactTelephoneNumber}
                </a>
            </div>
            {sitewide.openingTimes ? (
                <div className="flow--small">
                    <span className="text-label">Opening Times</span>
                    <p className="text-metadata text-color--white">
                        {sitewide.openingTimes.map((openingTime) => `${openingTime.openingDay} ${openingTime.openingHours}`)}
                    </p>
                </div>
            ) : null}
            <SocialIcons />
            <ResponsiblePharmacist />
        </div>
    );
};

export default CompanyDetails;
