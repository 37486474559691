import React from 'react';

const IconStatusSuccess = () => (
    <path
        fillRule="evenodd"
        d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48ZM34.7248 16.689C35.1053 16.2887 35.0893 15.6557 34.689 15.2752C34.2887 14.8947 33.6557 14.9107 33.2752 15.311L18.3554 31.0072L13.7026 26.4135C13.3096 26.0255 12.6764 26.0295 12.2884 26.4225C11.9004 26.8155 11.9044 27.4487 12.2974 27.8367L17.6755 33.1464C17.8665 33.335 18.1255 33.4389 18.3939 33.4347C18.6624 33.4304 18.9179 33.3184 19.1029 33.1237L34.7248 16.689Z"
    />
);

export default IconStatusSuccess;
