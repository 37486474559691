import React from 'react';
import PropTypes from 'prop-types';
import CraftMenuLink from '../../../CraftMenuLink/CraftMenuLink';

/** If a main navigation item has children but there's no mega menu, this is shown. */
const MainNavigationDropdown = ({ node }) => (
    <div className="card card--basic dropdown">
        <ul className="card__inner dropdown__links">
            {node.children.map((child, c) => (
                <li key={c}>
                    <CraftMenuLink menuNode={child} className="text-metadata text-color--default">
                        {child.title}
                    </CraftMenuLink>
                </li>
            ))}
        </ul>
    </div>
);

MainNavigationDropdown.propTypes = {
    node: PropTypes.shape({
        title: PropTypes.string,
        children: PropTypes.arrayOf(
            PropTypes.shape({
                element: PropTypes.shape({
                    slug: PropTypes.string,
                    parent: PropTypes.shape({
                        slug: PropTypes.string,
                    }),
                }),
            })
        ),
    }).isRequired,
};

export default MainNavigationDropdown;
