import React from 'react';
import PropTypes from 'prop-types';

import useTreatmentImage from '../../../data/staticQueryHooks/useTreatmentImage';
import formatQuantityLabel from '../../Treatment/helpers/formatQuantityLabel';

const AccountTreatmentList = ({ items, limit, className }) => {
    const { getTreatmentImage } = useTreatmentImage();

    let listItems = items;
    if (limit) listItems = listItems.slice(0, limit);

    return (
        <ul className={`acc-treatment-list ${className}`}>
            {listItems.map((item) => {
                const treatmentImage = getTreatmentImage(item.reference) || '/favicon.ico';
                return (
                    <li className="order-listing__item" key={item.reference}>
                        <img
                            src={treatmentImage}
                            className="image--item-thumb flex--no-grow flex--no-shrink"
                            alt={`The packaging of ${item.treatment}`}
                        />
                        <div className="flex--grow">
                            <span className="text-label display--block">{item.treatment}</span>
                            <span className="text-metadata display--block">Quantity: {formatQuantityLabel(item)}</span>
                        </div>
                    </li>
                );
            })}
            {items.length > limit ? (
                <li className="order-listing__item">
                    <span className="image--item-thumb image--surplus">+ {items.length - limit}</span>
                    <span className="text-metadata">+ {items.length - limit} items</span>
                </li>
            ) : null}
        </ul>
    );
};

AccountTreatmentList.defaultProps = {
    limit: undefined,
    className: '',
};

AccountTreatmentList.propTypes = {
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    limit: PropTypes.number,
    className: PropTypes.string,
};

export default AccountTreatmentList;
