import React from 'react';
import PropTypes from 'prop-types';

const FieldWrapper = ({ id, optional, disabled, label, subLabel, children }) => (
    <div className={`form-field__wrapper ${disabled ? 'form-field__wrapper--disabled' : ''}`}>
        <div>
            {label ? (
                <label htmlFor={id} className="font-weight--bold line-height--crop cursor--pointer">
                    <span>{label}</span>
                    {optional ? <span className="font-weight--regular text-color--slate"> (Optional)</span> : null}
                </label>
            ) : null}
            {subLabel ? <p className="text-color--slate">{subLabel}</p> : null}
        </div>
        {children}
    </div>
);

FieldWrapper.defaultProps = {
    optional: false,
    disabled: false,
    label: undefined,
    subLabel: undefined,
};

FieldWrapper.propTypes = {
    id: PropTypes.string.isRequired,
    optional: PropTypes.bool,
    disabled: PropTypes.bool,
    label: PropTypes.node,
    subLabel: PropTypes.node,
    children: PropTypes.node.isRequired,
};

export default FieldWrapper;
