import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { CRAFT_URL } from '../../../config/config';

/** Creates a way for our menu system to work out the link that needs to be displayed and whether its read only or not. */
const CraftMenuLink = ({ menuNode, className, children, activeClassName, onClick, ...rest }) => {
    const timeout = useRef();

    const handleButtonClick = () => {
        if (onClick) {
            onClick();
            return;
        }

        document.body.classList.add('_js-nav-megamenu-cancel');
        timeout.current = setTimeout(() => {
            document.body.classList.remove('_js-nav-megamenu-cancel');
        }, [500]);
    };

    useEffect(
        () => () => {
            // On unmount clear timeout
            if (timeout.current) {
                document.body.classList.remove('_js-nav-megamenu-cancel');
                clearTimeout(timeout.current);
            }
        },
        []
    );

    switch (menuNode.type) {
        case 'craft\\elements\\Entry':
            return (
                <Link
                    {...rest}
                    activeClassName={activeClassName}
                    to={menuNode.url.replace(CRAFT_URL, '')}
                    className={className}
                    onClick={handleButtonClick}
                >
                    {children}
                </Link>
            );

        case 'verbb\\navigation\\nodetypes\\PassiveType':
            return (
                <span {...rest} className={className}>
                    {children}
                </span>
            );

        default:
            return (
                <Link {...rest} activeClassName={activeClassName} to={menuNode.url} className={className} onClick={handleButtonClick}>
                    {children}
                </Link>
            );
    }
};

CraftMenuLink.defaultProps = {
    className: null,
    activeClassName: null,
    onClick: null,
};

CraftMenuLink.propTypes = {
    menuNode: PropTypes.shape({
        type: PropTypes.string,
        url: PropTypes.string,
        element: PropTypes.shape({
            slug: PropTypes.string,
            parent: PropTypes.shape({
                slug: PropTypes.string,
            }),
        }),
    }).isRequired,
    className: PropTypes.string,
    activeClassName: PropTypes.string,
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
};

export default CraftMenuLink;
