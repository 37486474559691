import React, { Fragment } from 'react';

const IconTruckTwoTone = () => (
    <Fragment>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26.8386 6.25H1.52987C0.684947 6.25 0 6.93495 0 7.77987V37.4175C0 38.2624 0.684947 38.9474 1.52987 38.9474H4.12202C4.77748 41.2219 6.85716 42.8986 9.34457 42.8986C11.832 42.8986 13.9117 41.2219 14.5671 38.9474H33.4309C34.0864 41.2219 36.1661 42.8986 38.6535 42.8986C41.1409 42.8986 43.2206 41.2219 43.876 38.9474H46.4701C47.3151 38.9474 48 38.2624 48 37.4175V29.5141C48 29.4954 47.9997 29.4768 47.999 29.4583V24.5741C47.999 24.1359 47.8111 23.7187 47.4829 23.4284L39.6672 16.5129C39.3875 16.2654 39.0269 16.1288 38.6534 16.1288H28.3686V7.77987C28.3686 6.93495 27.6835 6.25 26.8386 6.25ZM37.7221 19H32.5C31.9477 19 31.5 19.4477 31.5 20V28.25C31.5 28.8023 31.9477 29.25 32.5 29.25H44C44.5523 29.25 45 28.8023 45 28.25V25.4142C45 25.1233 44.8734 24.8468 44.6531 24.6569L38.3752 19.2427C38.1936 19.0861 37.9619 19 37.7221 19Z"
            fill="#B3E6FA"
        />
        <path
            d="M1 6.25C0.447715 6.25 0 6.69771 0 7.25V28.25C0 28.8023 0.447715 29.25 1 29.25H27.4C27.9523 29.25 28.4 28.8023 28.4 28.25V7.25C28.4 6.69772 27.9523 6.25 27.4 6.25H1Z"
            fill="#15A9E3"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 37.4564C4 34.459 6.40741 32 9.41277 32C12.4181 32 14.8255 34.459 14.8255 37.4564C14.8255 40.4538 12.4181 42.9128 9.41277 42.9128C6.40741 42.9128 4 40.4538 4 37.4564ZM7 37.4564C7 36.0815 8.09838 35 9.41277 35C10.7272 35 11.8255 36.0815 11.8255 37.4564C11.8255 38.8313 10.7272 39.9128 9.41277 39.9128C8.09838 39.9128 7 38.8313 7 37.4564Z"
            fill="#15A9E3"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M33.1745 37.4564C33.1745 34.459 35.5819 32 38.5872 32C41.5926 32 44 34.459 44 37.4564C44 40.4538 41.5926 42.9128 38.5872 42.9128C35.5819 42.9128 33.1745 40.4538 33.1745 37.4564ZM36.1745 37.4564C36.1745 36.0815 37.2728 35 38.5872 35C39.9016 35 41 36.0815 41 37.4564C41 38.8313 39.9016 39.9128 38.5872 39.9128C37.2728 39.9128 36.1745 38.8313 36.1745 37.4564Z"
            fill="#15A9E3"
        />
    </Fragment>
);

export default IconTruckTwoTone;
