import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import BaseIcon from '@components/_ui/_blocks/Icons/BaseIcon';
import IconChevronDown from '@components/_ui/_blocks/Icons/Navigation/IconChevronDown';
import MegamenuTreatments from './MegamenuTreatments';
import MegamenuConditions from './MegamenuConditions';

import CraftMenuLink from '../../../CraftMenuLink/CraftMenuLink';
import withLocation, { locationShape } from '../../../../../hoc/withLocation';

const componentList = {
    treatments: MegamenuTreatments,
    conditions: MegamenuConditions,
};

const MegaMenu = ({ node, conditions, location }) => {
    const { classes, children, title } = node;

    const type = classes.replace('_has-megamenu--', '');

    const ComponentToRender = componentList[type] || null;

    const hideMegaMenu = location.pathname === '/' && type === 'treatments'; // If the route is the homepage and the menu item is treatments, hide the megamenu.
    const disableMegaMenu = type === 'treatments' && location.pathname.includes('/gppc'); // If the menu item is the treatments item and contains "/gppc".

    return (
        <Fragment>
            <CraftMenuLink menuNode={node} className="nav-main__link" activeClassName="_is-active">
                <span>{title}</span>
                {disableMegaMenu === false && hideMegaMenu === false ? (
                    <BaseIcon className="icon--xxsmall">
                        <IconChevronDown />
                    </BaseIcon>
                ) : null}
            </CraftMenuLink>
            {disableMegaMenu === false ? (
                <ComponentToRender nodes={children} conditions={conditions} className={hideMegaMenu ? 'nav-megamenu--hide' : ''} />
            ) : null}
        </Fragment>
    );
};

MegaMenu.defaultProps = {
    conditions: [],
};

MegaMenu.propTypes = {
    node: PropTypes.shape({
        title: PropTypes.string,
        classes: PropTypes.string,
        children: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                title: PropTypes.string,
                level: PropTypes.string,
                url: PropTypes.string,
                parent: PropTypes.shape({
                    id: PropTypes.string,
                }),
            })
        ),
    }).isRequired,
    /** The navigation tree to be rendered */

    conditions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
        })
    ),
    /**
     * HOC - pulled from reach router.
     */
    location: locationShape.isRequired,
};

export default withLocation(MegaMenu);
