import { useEffect } from 'react';

const useLockViewportScrolling = (locked) => {
    useEffect(() => {
        if (locked) {
            document.body.style.overflow = 'hidden';
            return;
        }

        document.body.style.removeProperty('overflow');
    }, [locked]);

    useEffect(
        () => () => {
            document.body.style.removeProperty('overflow');
        },
        []
    );
};

export default useLockViewportScrolling;
