import React, { Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import BaseIcon from '@components/_ui/_blocks/Icons/BaseIcon';
import IconStatusHelp from '@components/_ui/_blocks/Icons/Status/IconStatusHelp';

// TODO: Rename helper to question helper
// TODO: css rename form-helper to question helper. this is specific to the question forms, we may have lifted this too early.
const HelperInline = ({ buttonText, content }) => {
    const [showOverlay, setShowOverlay] = useState(false);

    const openButtonRef = useRef();

    const openOverlay = () => {
        setShowOverlay(true);
        // closeButtonRef.current.focus();
    };

    const closeOverlay = () => {
        setShowOverlay(false);
        // openButtonRef.current.focus();
    };

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('close_all_helpers', closeOverlay, false);
        }
        return () => {
            if (typeof window !== 'undefined') {
                window.removeEventListener('close_all_helpers', closeOverlay);
            }
        };
    }, [showOverlay]);

    if (!content) return null;

    return (
        <Fragment>
            <button
                ref={openButtonRef}
                className="form-helper__button"
                type="button"
                onClick={!showOverlay ? openOverlay : closeOverlay}
                title="View more information about this question"
            >
                <BaseIcon>
                    <IconStatusHelp />
                </BaseIcon>
                <span>{showOverlay ? buttonText.open : buttonText.closed}</span>
            </button>
            {showOverlay && (
                <div className="form-helper__content--inline _a-full-fade-in ">
                    <div className="wysiwyg" dangerouslySetInnerHTML={{ __html: content }} />
                </div>
            )}
        </Fragment>
    );
};

HelperInline.defaultProps = {
    buttonText: {
        closed: 'Need help?',
        open: 'Hide help',
    },
    content: undefined,
};

HelperInline.propTypes = {
    buttonText: PropTypes.shape({
        closed: PropTypes.string.isRequired,
        open: PropTypes.string.isRequired,
    }),
    content: PropTypes.string,
};

export default HelperInline;
