import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';

const PricePreview = ({ treatment }) => {
    let price = '-';
    if (get(treatment, 'quantity.price')) {
        price = `£${(get(treatment, 'quantity.price') / 100).toFixed(2)}`;
    }

    return (
        <div className="flex flex--space-between island--large">
            <p className="text-label">Subtotal</p>
            <p className="text-price no-margin">{price}</p>
        </div>
    );
};

PricePreview.propTypes = {
    treatment: PropTypes.object.isRequired,
};

export default PricePreview;
