import React from 'react';
import PropTypes from 'prop-types';

const BusyIcon = ({ animate }) => (
    <span className={`busy-icon ${animate ? 'busy-icon--animate' : ''}`}>
        <span />
        <span />
        <span />
        <span />
    </span>
);

BusyIcon.defaultProps = {
    animate: true,
};

BusyIcon.propTypes = {
    animate: PropTypes.bool,
};

export default BusyIcon;
