import React from 'react';
import BaseIcon from './_blocks/Icons/BaseIcon';
import findIcon from './_blocks/Icons/helpers/findIcon';

interface Props {
    id?: string;
    heading?: string;
    className?: string;
    type?: 'warning' | 'success' | 'info' | 'error';
    children?: React.ReactNode;
    fullwidthChildren?: boolean;
    includeIcon?: boolean;
}

const Notification = ({ className, type, children, heading, fullwidthChildren, includeIcon, ...rest }: Props): JSX.Element => (
    <div className={`alert alert--${type} ${className}`} {...rest}>
        <div className={`flex breathe ${fullwidthChildren ? 'flex--align-center' : null}`}>
            {includeIcon && <BaseIcon className={`icon--medium icon--${type} flex--no-shrink`}>{findIcon(`icon-status-${type}`)}</BaseIcon>}
            <div className="flow--small flex--grow">
                {heading ? (
                    <header className="alert__header">
                        <h5 className={`notification__title text-color--${type}`}>{heading}</h5>
                    </header>
                ) : null}
                {!fullwidthChildren && children ? <div className="flow">{children}</div> : null}
            </div>
        </div>
        {fullwidthChildren ? <div className="flow">{children}</div> : null}
    </div>
);

Notification.defaultProps = {
    id: null,
    className: '',
    type: 'warning',
    heading: null,
    children: null,
    fullwidthChildren: false,
    includeIcon: true,
};

export default Notification;
