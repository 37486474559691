import React, { useEffect, useState, useMemo, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';

import { useAuthContext } from '../../../data/context/authContext';
import { API_ERROR_MESSAGE } from '../../../constants';
import useTreatmentBlockedMessages from '../../Treatment/TreatmentSelector/hooks/useTreatmentBlockedMessages';
import getLatestTreatmentReorderDetails from './helpers/getLatestTreatmentReorderDetails';

import ButtonBusy from '../../_ui/_blocks/Button/ButtonBusy';
import Notification from '../../_ui/Notification';
import IconCross from '../../_ui/_blocks/Icons/Navigation/IconCross';
import BaseIcon from '../../_ui/_blocks/Icons/BaseIcon';
import ConsultationReorderConfirmSkeleton from './ConsultationReorderConfirmSkeleton';
import TreatmentSelector from '../../Treatment/TreatmentSelector/TreatmentSelector';
import PriceBreak from './PriceBreak';
import PricePreview from './PricePreview';
import getSelectedTreatment from './helpers/getSelectedTreatment';
import dispatchGoogleAnalyticsEvent, { gaEvents, gaFormatters } from '../../../modules/analytics/google/dispatchGoogleAnalyticsEvent';

const ConsultationReorderConfirm = ({
    loadingTreatment,
    treatmentError,
    getConsultationPanels,
    loadingPanels,
    treatmentToReorder,
    treatment,
    onClose,
}) => {
    // Gets the patient gender from the patient context.
    const { patient } = useAuthContext();
    const patientGender = get(patient, 'personal.gender') || null;

    // The currently selected treatment.
    const [selectedTreatment, setSelectedTreatment] = useState({
        variant: undefined,
        quantity: undefined,
    });

    // A order may have out of date treatment details saved on the mongo document. This gets the latest
    // details using the reorder as a reference and then using the treatment data from the API.
    const latestTreatmentDetails = useMemo(() => {
        if (treatmentToReorder === null || !treatment) {
            return null;
        }

        return getLatestTreatmentReorderDetails(treatmentToReorder, treatment);
    }, [treatment, treatmentToReorder]);

    // Checks if there are any issues with the selected treatment.
    const { blockerMessages } = useTreatmentBlockedMessages({ treatment, selectedVariation: selectedTreatment, patientGender });

    // If the treatment selector should be shown or not.
    const [showTreatmentSelector, setShowTreatmentSelector] = useState(false);
    const handleChangeToSelector = treatment && !showTreatmentSelector && !blockerMessages ? () => setShowTreatmentSelector(true) : null;

    // Formats the selected treatment for the consultation process.
    const treatmentData = useMemo(() => {
        if (!selectedTreatment.variant || !selectedTreatment.quantity || !treatment) {
            return null;
        }

        return {
            conditionId: treatment.condition_id,
            treatmentId: selectedTreatment.variant.id,
            quantity: selectedTreatment.quantity.quantity,
            selectedVariant: selectedTreatment.variant,
            selectedQuantityInfo: selectedTreatment.quantity,
            selectedTreatment: treatment,
        };
    }, [selectedTreatment, treatment]);

    // Sends ecom analytics data.
    const ecomHasRun = useRef(false);
    useEffect(() => {
        if (treatmentData === null || ecomHasRun.current) {
            return;
        }

        dispatchGoogleAnalyticsEvent(gaEvents.RepeatPurchaseStart, gaFormatters.consultation(treatmentData, true, 'REFILL'));
        ecomHasRun.current = true;
    }, [treatmentData]);

    // When the reorder treatment form is submitted.
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (treatmentData === null) {
            return;
        }

        dispatchGoogleAnalyticsEvent(gaEvents.RepeatPurchaseComplete, gaFormatters.consultation(treatmentData, true, 'REFILL'));

        await getConsultationPanels(treatmentData);
        onClose();
    };

    // On load, get the reorder treatment details from the treatment data for the selected treatment state.
    useEffect(() => {
        if (!treatment) {
            return;
        }

        setSelectedTreatment(getSelectedTreatment(treatment, treatmentToReorder.reference, treatmentToReorder.quantity));
    }, [treatment, treatmentToReorder]);

    // If there was an error from the APi getting the treatment details.
    const apiError = useMemo(() => {
        if (!treatmentError) {
            return null;
        }

        const errorIs404 = get(treatmentError, 'response.status') === 404;

        return (
            <Notification
                className="flow--small notification--small"
                type={errorIs404 ? 'info' : 'error'}
                heading={errorIs404 ? 'Product unavailable' : 'Product details error'}
            >
                <p className="text-metadata">{errorIs404 ? 'This product is currently unavailable.' : API_ERROR_MESSAGE}</p>
            </Notification>
        );
    }, [treatmentError]);

    // If there are other quantities available but not the refill specific one.
    const quantityUnavailable = useMemo(() => {
        if (selectedTreatment.quantity) {
            return null;
        }

        return (
            <Notification
                type="info"
                heading="Quantity unavailable"
                className="notification--small flow--small island--large"
                fullwidthChildren
            >
                <p className="text-metadata">The quantity of this treatment could not be found. Please select a different quantity.</p>
            </Notification>
        );
    }, [selectedTreatment]);

    // If the treatment is loading, return the treatment skeleton.
    if (loadingTreatment) {
        return <ConsultationReorderConfirmSkeleton />;
    }

    // If any notifications from the price break component should be shown.
    const disablePriceBreakNotifications = !!blockerMessages || !get(latestTreatmentDetails, 'quantity') || false;

    return (
        <div className="card flow--large dialog dialog--small">
            <header className="flow--small">
                <div className="flex flex--space-between">
                    <h3 className="text-title-six">Treatment refill</h3>
                    <button type="button" className="dialog__close" onClick={onClose} title="Close treatment refill">
                        <BaseIcon className="icon--midnight icon--small">
                            <IconCross />
                        </BaseIcon>
                    </button>
                </div>
                {!apiError ? <p className="text-metadata text-color--slate">Confirm your treatment before checkout</p> : null}
            </header>
            {apiError || (
                <Fragment>
                    <PriceBreak
                        treatment={latestTreatmentDetails}
                        previousTreatment={treatmentToReorder}
                        onClick={handleChangeToSelector}
                        disableNotifications={disablePriceBreakNotifications}
                    />
                    <hr className="hr" />
                    <form onSubmit={handleSubmit} className="space-y-1.5">
                        {showTreatmentSelector ? (
                            <TreatmentSelector
                                treatment={treatment}
                                onChange={setSelectedTreatment}
                                patientGender={patientGender}
                                isValid={!!blockerMessages}
                                selectedTreatment={selectedTreatment}
                            />
                        ) : null}
                        {blockerMessages || quantityUnavailable || (
                            <Fragment>
                                {!showTreatmentSelector ? <PricePreview treatment={selectedTreatment} /> : null}
                                <div className="breathe text-align--right">
                                    <button type="button" className="button" onClick={onClose}>
                                        Cancel
                                    </button>
                                    <ButtonBusy
                                        type="submit"
                                        className="button button--primary"
                                        disabled={!selectedTreatment.variant}
                                        title={`Confirm the repeat order of this ${get(treatment, 'name') || treatment}`}
                                        isBusy={loadingPanels}
                                    >
                                        Confirm
                                    </ButtonBusy>
                                </div>
                            </Fragment>
                        )}
                    </form>
                </Fragment>
            )}
        </div>
    );
};

ConsultationReorderConfirm.defaultProps = {
    treatmentError: null,
    treatment: null,
    treatmentToReorder: null,
};

ConsultationReorderConfirm.propTypes = {
    loadingTreatment: PropTypes.bool.isRequired,
    treatmentError: PropTypes.object,
    getConsultationPanels: PropTypes.func.isRequired,
    loadingPanels: PropTypes.bool.isRequired,
    treatmentToReorder: PropTypes.shape({
        reference: PropTypes.number.isRequired,
        treatment: PropTypes.string.isRequired,
        quantity: PropTypes.number.isRequired,
    }),
    treatment: PropTypes.object,
    onClose: PropTypes.func.isRequired,
};

export default ConsultationReorderConfirm;
