import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

const Overlay = ({ className, fixedToViewport, show, onHide, children }) => (
    <CSSTransition
        mountOnEnter
        unmountOnExit
        in={show}
        appear={show}
        timeout={{ enter: 500, exit: 300 }}
        className={`overlay ${className} ${fixedToViewport ? 'overlay--fixed' : ''}`}
        classNames={{
            // appearActive: 'overlay--drop-in',
            // appearDone: 'overlay--drop-in',
            enterActive: 'overlay--drop-in',
            enterDone: 'overlay--drop-in',
            exit: 'overlay--drop-out',
        }}
        onExited={onHide}
    >
        <div>{children}</div>
    </CSSTransition>
);

Overlay.defaultProps = {
    className: '',
    fixedToViewport: true,
    onHide: undefined,
    children: null,
};

Overlay.propTypes = {
    className: PropTypes.string,
    fixedToViewport: PropTypes.bool,
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func,
    children: PropTypes.node,
};

export default Overlay;
