import React from 'react';

const ConsultationReorderConfirmSkeleton = () => (
    <div className="card flow--large dialog dialog--small">
        <header className="flow--small">
            <div className="ssc__heading" style={{ maxWidth: '9rem' }} />
            <div className="ssc__text" />
        </header>
        <div className="flex breathe--large flex--align-center">
            <div className="ssc__image" style={{ width: '4rem', height: '4rem' }} />
            <div className="flow--small flex--grow">
                <div className="ssc__heading" />
                <div className="flex flex--space-between">
                    <div className="ssc__text" style={{ maxWidth: '8rem' }} />
                    <div className="ssc__text" style={{ marginTop: '0', maxWidth: '3rem' }} />
                </div>
            </div>
        </div>
        <hr className="hr" />
        <div className="flex flex--space-between">
            <div className="ssc__text" style={{ maxWidth: '5rem' }} />
            <div className="ssc__heading" style={{ maxWidth: '4rem' }} />
        </div>
        <footer className="breathe text-align--right">
            <div className="ssc__button" style={{ maxWidth: '8rem' }} />
            <div className="ssc__button" style={{ maxWidth: '8rem' }} />
        </footer>
    </div>
);

export default ConsultationReorderConfirmSkeleton;
