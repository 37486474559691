import React from 'react';
import clx from 'classnames';

import * as styles from './BaseIcon.module.css';

enum Colors {
    'tertiary-300' = 'fill-tertiary-300',
    'tertiary-400' = 'fill-tertiary-400',
    'secondary-100' = 'fill-secondary-100',
    'secondary-500' = 'fill-secondary-500',
    'secondary-600' = 'fill-secondary-600',
    'sky-300' = 'fill-sky-300',
    'white' = 'fill-neutrals-white',
    'black' = 'fill-neutrals-black',
    'highlight' = 'fill-highlight',
    'error-500' = 'fill-error-500',
}

interface Props {
    children: React.ReactNode;
    size?: 'xxsmall' | 'xsmall' | 'small' | 'medium' | 'large';
    width?: number;
    height?: number;
    viewBox?: string;
    color?: keyof typeof Colors;
    className?: string;
}

const BaseIcon = ({ children, size = 'medium', width, height, viewBox = '0 0 48 48', color = 'secondary-100', className }: Props) => {
    const classNames = clx('pointer-events-none', className, styles[size], Colors[color]);
    return (
        <svg viewBox={viewBox} className={`pointer-events-none ${classNames}`} width={width} height={height}>
            {children}
        </svg>
    );
};

export default BaseIcon;
