// extracted by mini-css-extract-plugin
export var bestSeller = "Tag-module--bestSeller--cc62a";
export var borderRadiusFull = "Tag-module--borderRadiusFull--a9298";
export var borderRadiusSmall = "Tag-module--borderRadiusSmall--04e85";
export var bundlePack = "Tag-module--bundlePack--990ab";
export var error = "Tag-module--error--4564a";
export var info = "Tag-module--info--e31e0";
export var large = "Tag-module--large--fde19";
export var medium = "Tag-module--medium--885b5";
export var multiBuyOffer = "Tag-module--multiBuyOffer--ec36d";
export var newPackaging = "Tag-module--newPackaging--c4d70";
export var newProduct = "Tag-module--newProduct--e88f5";
export var regular = "Tag-module--regular--946ae";
export var sale = "Tag-module--sale--43954";
export var specialOffer = "Tag-module--specialOffer--abe7a";
export var success = "Tag-module--success--a4085";
export var tag = "Tag-module--tag--6f1fe";
export var warning = "Tag-module--warning--44886";