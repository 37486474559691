import React from 'react';

import { ProductsQuantity } from '../../../types/api/products/ProductsQuantity';
import formatQuantityLabel from '../../Treatment/helpers/formatQuantityLabel';
import formatPrice from '../../../helpers/formatPrice';

import Typography from '../common/Typography/Typography';

import * as styles from './PriceBreakListing.module.css';

interface Props {
    priceBreaks: ProductsQuantity[];
}

const PriceBreakListing = ({ priceBreaks }: Props) => {
    const sortedPriceBreaks = priceBreaks.sort((a, b) => (a.quantity > b.quantity ? 1 : -1));

    return (
        <ul className={styles.list}>
            {sortedPriceBreaks.map((priceBreak) => (
                <li key={priceBreak.quantity} className="flex justify-between gap-100">
                    <div>
                        <Typography as="div" variant="metadata" weight="medium" fontSize="090">
                            {formatQuantityLabel(priceBreak)}
                        </Typography>
                        <Typography as="div" variant="metadata" color="quiet" fontSize="080">
                            {formatPrice(priceBreak.unit_cost)} per pack
                        </Typography>
                    </div>
                    <div className="text-right">
                        <Typography as="div" variant="subheading" color="highlight" lineHeight="none">
                            {formatPrice(priceBreak.price)}
                        </Typography>
                        {priceBreak.saving ? (
                            <Typography as="div" variant="metadata" color="accent" fontSize="080">
                                Save {formatPrice(priceBreak.saving)}
                            </Typography>
                        ) : null}
                    </div>
                </li>
            ))}
        </ul>
    );
};

export default PriceBreakListing;
