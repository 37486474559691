import React from 'react';

const IconChevronDown = () => (
    <path
        fillRule="evenodd"
        d="M47.4142 11.5858C48.1953 12.3668 48.1953 13.6332 47.4142 14.4142L25.4142 36.4142C25.0391 36.7893 24.5304 37 24 37C23.4696 37 22.9609 36.7893 22.5858 36.4142L0.585787 14.4142C-0.195262 13.6332 -0.195262 12.3668 0.585786 11.5858C1.36683 10.8047 2.63317 10.8047 3.41421 11.5858L24 32.1716L44.5858 11.5858C45.3668 10.8047 46.6332 10.8047 47.4142 11.5858Z"
    />
);

export default IconChevronDown;
