/**
 * Checks if a logged in patient has the correct gender for a treatment.
 * @param {string} patientGender - Gender of the logged in patient if available.
 * @param {string} treatmentGender - Gender of the treatment being checked.
 * @returns boolean
 */
const isGenderSuitableForTreatment = (patientGender = null, treatmentGender = null) => {
    if (!patientGender || !treatmentGender || treatmentGender === 'n/a') return true;

    const genderGroup =
        treatmentGender === 'male'
            ? ['male', 'male-identifies-female', 'female-identifies-male']
            : ['female', 'male-identifies-female', 'female-identifies-male'];

    return genderGroup.includes(patientGender);
};

export default isGenderSuitableForTreatment;
