import React from 'react';
import SelectInput from './SelectInput/SelectInput';

export const genders = {
    male: 'Male',
    female: 'Female',
    'male-identifies-female': 'Trans Female (Male at birth)',
    'female-identifies-male': 'Trans Male (Female at birth)',
};

const aliases = {
    'male-identifies-female': 'male',
    'female-identifies-male': 'female',
};

type aliasKeys = keyof typeof aliases;

export const genderSlugToReadable = (slug: keyof typeof genders) => genders[slug];
export const convertGenderAlias = (alias: aliasKeys) => aliases[alias] || alias;

interface Props {
    value?: keyof typeof genders | '';
    gender?: keyof typeof genders | '';
    defaultValue?: keyof typeof genders;
    /** If the field value is valid, if not add class. */
    isValid?: boolean;
    onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const SelectGender = ({ gender, value, defaultValue, onChange, isValid, ...rest }: Props) => (
    <SelectInput
        id="form-field--gender"
        name="gender"
        placeholder="Select your sex..."
        onChange={onChange}
        isValid={isValid}
        value={gender || value || ''}
        className="form-select--default-width"
        options={Object.keys(genders).map((g) => ({ text: genders[g as keyof typeof genders], value: g }))}
        defaultValue={defaultValue}
        {...rest}
    />
);

SelectGender.defaultProps = {
    gender: undefined,
    defaultValue: undefined,
    isValid: true,
    onChange: undefined,
};

export default SelectGender;
