import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import formatOutOfStockMessage from './formatters/formatOutOfStockMessage';

import BaseIcon from '../../../../_ui/_blocks/Icons/BaseIcon';
import IconArrowRight from '../../../../_ui/_blocks/Icons/Navigation/IconArrowRight';
import Notification from '../../../../_ui/Notification';

const OutOfStockQuantity = ({ treatment, conditionName, conditionSlug }) => {
    const outOfStockMessage = formatOutOfStockMessage(treatment, conditionSlug);

    return (
        <Notification type="error" heading="Out of stock" className="notification--small flow">
            <div className="flow--small">
                {outOfStockMessage.message ? (
                    <div
                        className="text-metadata"
                        dangerouslySetInnerHTML={{
                            __html: outOfStockMessage.message,
                        }}
                    />
                ) : (
                    <div className="text-metadata">
                        <p>
                            Sorry, this item is currently out of stock and we do not have a date for when it will be available from the
                            manufacturer.
                        </p>
                        <p>You can view our alternative treatments for {conditionName} below.</p>
                    </div>
                )}

                <div>
                    <Link
                        className="text-link text-link--inline flex breathe flex--align-center"
                        to={outOfStockMessage.buttonUrl}
                        title={outOfStockMessage.buttonText}
                    >
                        <span>{outOfStockMessage.buttonText}</span>
                        <BaseIcon className="font-size--100">
                            <IconArrowRight />
                        </BaseIcon>
                    </Link>
                </div>
            </div>
        </Notification>
    );
};

OutOfStockQuantity.propTypes = {
    conditionSlug: PropTypes.string.isRequired,
    conditionName: PropTypes.string.isRequired,
    treatment: PropTypes.shape({
        condition_name: PropTypes.string.isRequired,
    }).isRequired,
};

export default OutOfStockQuantity;
