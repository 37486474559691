import PropTypes from 'prop-types';

import { ORDER_TYPES, QUANTITY_TYPES } from '../../../constants';

const orderTypes = Object.keys(ORDER_TYPES).map((type) => ORDER_TYPES[type]);

export const quantityShape = PropTypes.shape({
    quantity: PropTypes.number.isRequired,
    unit_cost: PropTypes.number.isRequired,
    price: PropTypes.number.isRequired,
    label: PropTypes.string,
    default: PropTypes.bool,
});

export const timedModifiersShape = PropTypes.shape({
    before: PropTypes.number,
    after: PropTypes.number,
    daily: PropTypes.number,
    max: PropTypes.number,
});

export const variantShape = PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.oneOf(orderTypes).isRequired,
    name: PropTypes.string.isRequired,
    default: PropTypes.bool,
    course_length_label: PropTypes.string,
    image: PropTypes.string,
    timed_explainer: PropTypes.string,
    timed_modifiers: timedModifiersShape,
    quantities: PropTypes.arrayOf(quantityShape),
    available: PropTypes.bool,
    quantity_type: PropTypes.oneOf(Object.keys(QUANTITY_TYPES).map((type) => QUANTITY_TYPES[type])).isRequired,
    blocker_ids: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.bool])),
    url: PropTypes.string.isRequired,
});

export const treatmentShape = PropTypes.shape({
    id: PropTypes.number.isRequired,
    parent: PropTypes.oneOfType([
        PropTypes.oneOf([0]),
        PropTypes.shape({
            default_child: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            reference: PropTypes.number.isRequired,
        }),
    ]),
    type: PropTypes.oneOf(orderTypes).isRequired,
    name: PropTypes.string.isRequired,
    variants: PropTypes.arrayOf(variantShape),
    delivery: PropTypes.shape({
        diff: PropTypes.string,
        deliveryDate: PropTypes.string,
    }),
});
