import { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import get from 'lodash.get';

const formatTreatmentsToIndex = (treatmentData) => {
    const treatmentItems = get(treatmentData, 'allTreatment.nodes');

    const treatmentImageIndex = {};

    treatmentItems.forEach((t) => {
        const { entry, computed } = t;
        const { systemId } = entry.treatmentSystemIds[0];
        const imgSrc = entry.treatmentFeaturedImage[0] ? entry.treatmentFeaturedImage[0].url : null;
        const imgMediumSrc = entry.treatmentFeaturedImage[0] ? entry.mediumFeaturedImage[0].url : null;
        const treatmentSlug = t.slug || null;
        const treatmentTitle = entry.title || null;
        const conditionSlug = entry.treatmentPrimaryCondition[0] ? entry.treatmentPrimaryCondition[0].slug : null;
        const treatmentDescription = entry.snippet;
        const rating = get(computed, 'selectedReview.average_rating') || 0;
        const reviewCount = get(computed, 'selectedReview.num_ratings') || 0;

        treatmentImageIndex[systemId] = {
            imgSrc,
            imgMediumSrc,

            treatmentSlug,
            treatmentTitle,
            conditionSlug,
            treatmentDescription,
            rating,
            reviewCount,
        };
    });

    return treatmentImageIndex;
};

/**
 * This is used for images and slugs to get the urls for a treatment
 * We need to keep this small because it will create and cache a json file.
 */
const useTreatments = () => {
    const treatmentsQueryData = useStaticQuery(graphql`
        {
            allTreatment {
                nodes {
                    slug
                    computed {
                        selectedReview {
                            average_rating
                            num_ratings
                        }
                    }
                    entry {
                        title
                        snippet
                        treatmentFeaturedImage {
                            url
                        }
                        mediumFeaturedImage {
                            url
                        }
                        largeFeaturedImage {
                            url
                        }
                        treatmentSystemIds {
                            systemId
                        }
                        treatmentPrimaryCondition {
                            slug
                        }
                        treatmentSecondaryConditions {
                            slug
                        }
                    }
                }
            }
        }
    `);

    const treatmentIndex = useMemo(() => formatTreatmentsToIndex(treatmentsQueryData), [treatmentsQueryData]);

    const getTreatment = (systemId) => treatmentIndex[systemId];
    const getTreatmentTitle = (systemId) => get(treatmentIndex, `${systemId}.treatmentTitle`) || null;
    const getTreatmentSlug = (systemId) => (treatmentIndex[systemId] ? treatmentIndex[systemId].treatmentSlug : null);
    const getConditionSlug = (systemId) => (treatmentIndex[systemId] ? treatmentIndex[systemId].conditionSlug : null);
    const getTreatmentDescription = (systemId) => (treatmentIndex[systemId] ? treatmentIndex[systemId].treatmentDescription : null);

    return {
        treatmentsQueryData,
        treatmentIndex,
        getTreatment,
        getTreatmentSlug,
        getTreatmentTitle,
        getConditionSlug,
        getTreatmentDescription,
    };
};

export default useTreatments;
