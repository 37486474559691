// extracted by mini-css-extract-plugin
export var bordered = "Button-module--bordered--ac044";
export var button = "Button-module--button--f9610";
export var disabled = "Button-module--disabled--d963a";
export var ghost = "Button-module--ghost--8cba6";
export var large = "Button-module--large--1c378";
export var medium = "Button-module--medium--c9b62";
export var primary = "Button-module--primary--c29e5";
export var reset = "Button-module--reset--146fb";
export var secondary = "Button-module--secondary--6f266";
export var small = "Button-module--small--79039";