import React, { useMemo } from 'react';
import get from 'lodash.get';
import { useCookieWatcher } from '../../../helpers/cookie';
import { getAffiliateJwtDecoded } from '../helpers/getAffiliateJwt';
import svgInPartnershipWithPatientLogo from './InPartnershipWithPatientLogo.svg';
import svgInPartnershipWithYoxlyLogo from './InPartnershipWithYoxlyLogo.svg';

const AffiliateBanner = () => {
    const [affiliateJwt] = useCookieWatcher('affiliate'); // we use this just to watch and perform rerenders.
    const decodedJwt = useMemo(() => getAffiliateJwtDecoded({}), [affiliateJwt]);
    const affiliateName = get(decodedJwt, 'affiliate_name', null);

    switch (affiliateName) {
        case 'Patient Access':
            return (
                <div className="affiliate-banner">
                    <div className="container container--large">
                        <img alt="Patient Access Logo" src={svgInPartnershipWithPatientLogo} />
                    </div>
                </div>
            );
        case 'Yoxly':
            return (
                <div className="affiliate-banner">
                    <div className="container container--large">
                        <img alt="Yoxly Logo" src={svgInPartnershipWithYoxlyLogo} />
                    </div>
                </div>
            );
        default:
            return null;
    }
};

export default AffiliateBanner;
