import React from 'react';
import PropTypes from 'prop-types';

import { QUANTITY_TYPES } from '../../../../constants';
import { variantShape } from '../../propTypes/treatmentShape';

import RegularQuantity from './RegularQuantity/RegularQuantity';
import TimedQuantity from './TimedQuantity/TimedQuantity';
import TreatmentSelectorPriceBreaks from '../../../_new_components/TreatmentSelector/TreatmentSelectorPriceBreaks/TreatmentSelectorPriceBreaks';

const QuantitySelector = ({ selectedVariant, selectedQuantity, enableQuantityListing, onChange }) => {
    const isOdOnly = selectedVariant?.type?.toUpperCase() === 'ODONLY';

    if (!selectedVariant) return null;

    if (enableQuantityListing && isOdOnly && selectedVariant.quantity_type.toUpperCase() === QUANTITY_TYPES.regular) {
        return <TreatmentSelectorPriceBreaks priceBreaks={selectedVariant.quantities} currentStockLevel={selectedVariant.stock} />;
    }

    if (selectedVariant.quantity_type.toUpperCase() === QUANTITY_TYPES.regular) {
        return (
            <RegularQuantity
                selectedVariantType={selectedVariant.type.toUpperCase()}
                selectedQuantity={selectedQuantity}
                quantities={selectedVariant.quantities}
                onChange={onChange}
            />
        );
    }

    return (
        <TimedQuantity
            modifiers={selectedVariant.timed_modifiers}
            explanation={selectedVariant.timed_explainer}
            quantity={selectedVariant.quantities[0]}
            onChange={onChange}
        />
    );
};

QuantitySelector.defaultProps = {
    selectedVariant: undefined,
    selectedQuantity: undefined,
    outOfStockMessage: undefined,
    enableQuantityListing: false,
};

QuantitySelector.propTypes = {
    selectedVariant: variantShape,
    selectedQuantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func.isRequired,
    enableQuantityListing: PropTypes.bool,
    outOfStockMessage: PropTypes.shape({
        message: PropTypes.string,
        url: PropTypes.string,
        text: PropTypes.string,
    }),
};

export default QuantitySelector;
