import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import loadable from '@loadable/component';

import { useNavigationContext } from '../../data/context/navigationContext';
import { useBasketContext } from '../../data/context/basketContext/basketContext';

import BaseIcon from '../_ui/_blocks/Icons/BaseIcon';
import IconBasket from '../_ui/_blocks/Icons/IconBasket';
import IconMenu from '../_ui/_blocks/Icons/Navigation/IconMenu';
import NumberIncrement from '../_ui/_blocks/NumberIncrement/NumberIncrement';
import ImagePreload from '../_ui/_blocks/ImagePreload';
import MainNavigation from './CraftMenuTypes/MainNavigation/MainNavigation';
import AccountDropdown from '../_ui/Account/AccountDropdown';

const SearchHeader = loadable(() => import('../Search/SearchHeader/SearchHeader'));
const LocalStorageWarning = loadable(() => import('./LocalStorageWarning/LocalStorageWarning'));

/**
 * The main header component for the website.
 * @param {object} props
 * @returns {ReactElement}
 */
const Header = ({ enableMainNavigation, className }) => {
    const { setSidebarNavIsOpen } = useNavigationContext();
    const { basket, setBasketIsOpen } = useBasketContext();

    return (
        <header className={`header ${className}`}>
            <LocalStorageWarning />
            <div className="header__top header__top--no-padding-right">
                <SearchHeader />
                <Link
                    to="/"
                    className="header__logo"
                    title="The Independent Pharmacy homepage"
                    aria-label="The Independent Pharmacy homepage"
                >
                    <ImagePreload
                        src="/images/logo.svg"
                        alt="The Independent Pharmacy"
                        height="33.5"
                        width="125"
                        className="display--block"
                    />
                </Link>
                <div className="header__buttons">
                    <AccountDropdown disableAccountDropdownOnMobile />
                    <button
                        type="button"
                        className="header__button header__button--basket"
                        aria-label="Open your basket"
                        title="Open your basket"
                        onClick={(e) => {
                            e.preventDefault();
                            setBasketIsOpen((s) => !s);
                        }}
                    >
                        <span className="text-label text-color--midnight">Basket</span>
                        <BaseIcon className="icon--small icon--midnight">
                            <IconBasket />
                        </BaseIcon>
                        {basket && basket.items && basket.items.length ? (
                            <div className="header__basket-count pill">
                                <NumberIncrement number={basket.items.length} />
                            </div>
                        ) : null}
                    </button>
                    <button
                        type="button"
                        className="header__button header__button--menu"
                        onClick={() => setSidebarNavIsOpen(true)}
                        aria-label="Open the main menu"
                        title="Open the main menu"
                    >
                        <span className="text-label text-color--midnight">Menu</span>
                        <BaseIcon className="icon--small icon--midnight">
                            <IconMenu />
                        </BaseIcon>
                    </button>
                </div>
            </div>
            {enableMainNavigation && <MainNavigation />}
        </header>
    );
};

Header.defaultProps = {
    enableMainNavigation: true,
    className: '',
};

Header.propTypes = {
    /**
     * To show or hide the main navigation component.
     */
    enableMainNavigation: PropTypes.bool,
    /**
     * Extra class names that can be added.
     */
    className: PropTypes.string,
};

export default Header;
