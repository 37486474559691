import React from 'react';

import { useNavigationContext } from '../../../data/context/navigationContext';
import { useSearchContext } from '../../../data/context/searchContext';
import { emitNavigationOpenEvent } from '../../../hooks/useNavigationCloseHook';

import BaseIcon from '../common/Icons/BaseIcon/BaseIcon';
import IconMenu from '../../_ui/_blocks/Icons/Navigation/IconMenu';
import IconSearch from '../../_ui/_blocks/Icons/Navigation/IconSearch';
import Button from '../common/Buttons/Button/Button';
import Typography from '../common/Typography/Typography';
import SignInButton from './SignInButton/SignInButton';

import * as styles from './DrawerNavigation.module.css';

const DrawerNavigation = () => {
    const { setSearchBarIsOpen } = useSearchContext();
    const { setSidebarNavIsOpen } = useNavigationContext();

    const openSearch = () => {
        emitNavigationOpenEvent('search');
        setSearchBarIsOpen(true);
    };

    const openMobileMenu = () => {
        emitNavigationOpenEvent('mobile');
        setSidebarNavIsOpen(true);
    };

    return (
        <nav className={styles.drawer} aria-label="Nav Navigation" data-visual-test="transparent">
            <Button
                variant="none"
                size="none"
                className={styles.button}
                title="Open search bar"
                aria-label="Open search bar"
                onClick={openSearch}
            >
                <Typography variant="label">Search</Typography>
                <BaseIcon size="medium" color="tertiary-300">
                    <IconSearch />
                </BaseIcon>
            </Button>
            <SignInButton />
            <Button
                variant="none"
                size="none"
                className={styles.button}
                aria-label="Open the main menu"
                title="Open the main menu"
                onClick={openMobileMenu}
            >
                <Typography variant="label">Menu</Typography>
                <BaseIcon size="medium" color="tertiary-300">
                    <IconMenu />
                </BaseIcon>
            </Button>
        </nav>
    );
};

export default DrawerNavigation;
