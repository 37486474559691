import React from 'react';

const IconStatusHelp = () => (
    <path
        fillRule="evenodd"
        d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48ZM20.703 15.8704C19.9096 16.6531 19.5962 17.6152 19.5802 18.2651C19.5598 19.0933 18.8719 19.7481 18.0437 19.7277C17.2155 19.7073 16.5607 19.0194 16.5811 18.1913C16.6158 16.7828 17.2365 15.0759 18.5962 13.7346C19.9924 12.3574 22.0861 11.4425 24.9127 11.5122C27.8549 11.5846 29.7531 12.8658 30.8614 14.4522C31.9106 15.9539 32.1898 17.6328 32.1665 18.5752C32.1447 19.4614 31.9611 20.4338 31.3486 21.5348C30.7557 22.6008 29.7957 23.7245 28.3239 24.997C26.9866 26.1525 26.3235 26.951 25.9874 27.5197C25.6839 28.0332 25.6278 28.3884 25.6186 28.764L25.5833 30.1935C25.5629 31.0216 24.875 31.6765 24.0469 31.6561C23.2187 31.6357 22.5639 30.9478 22.5843 30.1196L22.6195 28.6901C22.6392 27.8877 22.8007 27.0153 23.4048 25.9933C23.9763 25.0263 24.9109 23.9813 26.3626 22.727C27.6779 21.5897 28.3632 20.7304 28.7269 20.0765C29.0712 19.4576 29.1558 18.9731 29.1674 18.5013C29.1777 18.0856 29.0251 17.062 28.4021 16.1703C27.8383 15.3633 26.8242 14.5602 24.8388 14.5113C22.7386 14.4595 21.46 15.1237 20.703 15.8704ZM24.0738 37.0088C24.9429 37.0088 25.6475 36.3104 25.6475 35.4489C25.6475 34.5874 24.9429 33.889 24.0738 33.889C23.2046 33.889 22.5 34.5874 22.5 35.4489C22.5 36.3104 23.2046 37.0088 24.0738 37.0088Z"
    />
);

export default IconStatusHelp;
