import React, { Fragment } from 'react';

const IconLock = () => (
    <Fragment>
        <path d="M24 29.25C24.8284 29.25 25.5 29.9216 25.5 30.75V38.25C25.5 39.0784 24.8284 39.75 24 39.75C23.1716 39.75 22.5 39.0784 22.5 38.25V30.75C22.5 29.9216 23.1716 29.25 24 29.25Z" />
        <path
            fillRule="evenodd"
            d="M35.25 21V11.25C35.25 8.26631 34.0647 5.40483 31.955 3.29505C29.8452 1.18526 26.9837 0 24 0C21.0163 0 18.1548 1.18526 16.045 3.29505C13.9353 5.40483 12.75 8.26631 12.75 11.25V21H12C8.68629 21 6 23.6863 6 27V42C6 45.3137 8.68629 48 12 48H36C39.3137 48 42 45.3137 42 42V27C42 23.6863 39.3137 21 36 21H35.25ZM24 3C21.812 3 19.7135 3.86919 18.1664 5.41637C16.6192 6.96354 15.75 9.06196 15.75 11.25V21H32.25V11.25C32.25 9.06196 31.3808 6.96354 29.8336 5.41637C28.2865 3.86919 26.188 3 24 3ZM12 24C10.3431 24 9 25.3431 9 27V42C9 43.6569 10.3431 45 12 45H36C37.6569 45 39 43.6569 39 42V27C39 25.3431 37.6569 24 36 24H12Z"
        />
    </Fragment>
);

export default IconLock;
