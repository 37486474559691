import React from 'react';

const IconBasket = () => (
    <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.25 4.75C0.25 3.92157 0.921573 3.25 1.75 3.25H9.167C9.85595 3.25 10.4563 3.71931 10.6226 4.38788L13.3064 15.176H46.252C46.7338 15.176 47.1862 15.4074 47.4682 15.798C47.7502 16.1886 47.8274 16.6909 47.6757 17.1482L41.7421 35.0372C41.5386 35.6508 40.9649 36.0649 40.3184 36.0649H37.7614C40.0419 36.2728 41.8182 38.1977 41.8182 40.5279C41.8182 42.9959 39.8254 45.0093 37.3514 45.0093C34.8775 45.0093 32.8846 42.9959 32.8846 40.5279C32.8846 38.1977 34.661 36.2728 36.9415 36.0649H18.4771C20.7576 36.2728 22.534 38.1977 22.534 40.5279C22.534 42.9959 20.5411 45.0093 18.0672 45.0093C15.5932 45.0093 13.6004 42.9959 13.6004 40.5279C13.6004 38.1977 15.3767 36.2728 17.6572 36.0649H16.584C15.8951 36.0649 15.2947 35.5956 15.1284 34.9271L10.6863 17.0707C10.6806 17.0499 10.6754 17.0289 10.6706 17.0077L7.99444 6.25H1.75C0.921573 6.25 0.25 5.57843 0.25 4.75ZM14.0527 18.176L17.7566 33.0649H39.2356L44.1741 18.176H14.0527ZM18.0672 39.0464C17.2641 39.0464 16.6004 39.7026 16.6004 40.5279C16.6004 41.3531 17.2641 42.0093 18.0672 42.0093C18.8703 42.0093 19.534 41.3531 19.534 40.5279C19.534 39.7026 18.8703 39.0464 18.0672 39.0464ZM35.8846 40.5279C35.8846 39.7026 36.5483 39.0464 37.3514 39.0464C38.1546 39.0464 38.8182 39.7026 38.8182 40.5279C38.8182 41.3531 38.1546 42.0093 37.3514 42.0093C36.5483 42.0093 35.8846 41.3531 35.8846 40.5279Z"
    />
);

export default IconBasket;
