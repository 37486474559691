import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { genericHashLink } from 'react-router-hash-link';
import loadable from '@loadable/component';

import { useAuthContext } from '../../../data/context/authContext';

const AccountSignInButtons = loadable(() => import('./AccountSignInButtons/AccountSignInButtons.tsx'));

const AccountDropdown = ({ disableAccountDropdownOnMobile }) => {
    const {
        isLoggedIn,
        logout
    } = useAuthContext();

    const ScrollLink = genericHashLink(Link);

    // Can't pass logout directly to the button as the redirect param will be passed as an event object.
    const handleLogout = () => logout();

    return (
        <div className="header__button--account _has-dropdown">
            <div className={disableAccountDropdownOnMobile ? 'display--none md:block' : undefined}>
                <AccountSignInButtons />
            </div>
            {isLoggedIn ? (
                <div className="card card--basic dropdown">
                    <ul className="card__inner dropdown__links">
                        <li>
                            <Link to="/account" className="text-metadata text-color--default">Dashboard</Link>
                        </li>
                        <li>
                            <ScrollLink
                                to="/account/orders#order-history"
                                className="text-metadata text-color--default"
                            >
                                Your orders
                            </ScrollLink>
                        </li>
                        <li>
                            <Link
                                to="/account/orders/treatment-reorders"
                                className="text-metadata text-color--default"
                            >
                                Treatment reorders
                            </Link>
                        </li>
                        <li>
                            <hr className="hr hr--tight hr--mid" />
                            <button
                                type="button"
                                className="text-metadata text-color--default button--strip"
                                onClick={handleLogout}
                            >
                                Sign out
                            </button>
                        </li>
                    </ul>
                </div>
            ) : null}
        </div>
    );
};


AccountDropdown.defaultProps = {
    disableAccountDropdownOnMobile: false,
};

AccountDropdown.propTypes = {
    disableAccountDropdownOnMobile: PropTypes.bool,
};

export default AccountDropdown;
