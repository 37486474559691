import React, { Fragment } from 'react';

const IconSearch = () => (
    <Fragment>
        <path
            fillRule="evenodd"
            d="M20.6452 3.09677C10.9535 3.09677 3.09677 10.9535 3.09677 20.6452C3.09677 30.3369 10.9535 38.1935 20.6452 38.1935C30.3369 38.1935 38.1935 30.3369 38.1935 20.6452C38.1935 10.9535 30.3369 3.09677 20.6452 3.09677ZM0 20.6452C0 9.24315 9.24315 0 20.6452 0C32.0472 0 41.2903 9.24315 41.2903 20.6452C41.2903 32.0472 32.0472 41.2903 20.6452 41.2903C9.24315 41.2903 0 32.0472 0 20.6452Z"
        />
        <path
            fillRule="evenodd"
            d="M34.0019 34.0019C34.6066 33.3972 35.587 33.3972 36.1916 34.0019L47.5465 45.3567C48.1512 45.9614 48.1512 46.9418 47.5465 47.5465C46.9418 48.1512 45.9614 48.1512 45.3567 47.5465L34.0019 36.1916C33.3972 35.587 33.3972 34.6066 34.0019 34.0019Z"
        />
    </Fragment>
);

export default IconSearch;
