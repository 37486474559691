/* eslint-disable react/prop-types */
import React, { Fragment, useEffect, useState } from 'react';
import LazyLoad from 'react-lazyload';

/**
 * This is a wrapper for lazy load component - use in gatsby.
 * This allows us to still server side render (SSR) the bits that we want for and then it lazy load them at run time.
 * This is useful for google and seo.
 * @param {props} - children and all properties / options that go onto the react-lazyload component
 */
const SSRLazyLoadWrap = ({ children, ...rest }) => {
    const [hasLoaded, setHasLoaded] = useState(false);

    useEffect(() => {
        setHasLoaded(true);
    }, []);

    if (!hasLoaded) return <Fragment> {children}</Fragment>;

    return <LazyLoad {...rest}>{children}</LazyLoad>;
};

export default SSRLazyLoadWrap;
