import { useEffect, useState } from 'react';

import { selectorScrollTo } from '../../../../../helpers/scrollTo';
import { isBrowser } from '../../../../../config/config';

const useScrollToPriceComparison = () => {
    const [priceComparisonAvailable, setPriceComparisonAvailable] = useState(false);

    useEffect(() => {
        const checkPriceComparisonTableExists = setTimeout(() => {
            if (!isBrowser()) return;
            setPriceComparisonAvailable(!!document.querySelector('#price-comparison'));
        }, 500);

        return () => {
            clearTimeout(checkPriceComparisonTableExists);
        };
    }, []);

    return priceComparisonAvailable ? () => selectorScrollTo('#price-comparison') : undefined;
};

export default useScrollToPriceComparison;
