import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import BaseIcon from '../../../_ui/_blocks/Icons/BaseIcon';
import IconCheckmark from '../../../_ui/_blocks/Icons/Navigation/IconCheckmark';

const RadioCheckboxBlock = ({
    id,
    name,
    type,
    className,
    label,
    subLabel,
    metadata,
    value,
    defaultChecked,
    checked,
    onChange,
    isValid,
    required,
    disabled,
    readOnly,
}) => {
    const isValidClass = !isValid ? 'form-field--error' : '';
    const isDisabledClass = disabled ? 'form-form--disabled' : '';
    const isActive = checked ? 'form-field--active' : '';
    const classes = `form-field ${isActive} ${isValidClass} ${isDisabledClass} form-radio-checkbox ${className}`;

    return (
        <Fragment>
            <input
                id={id}
                name={name}
                className={`form-radio-checkbox__input ${!isValid ? 'form-field--error' : ''}`}
                type={type}
                value={value}
                defaultChecked={defaultChecked}
                checked={!!checked}
                onChange={onChange}
                required={required}
                disabled={disabled && 'disabled'}
                tabIndex={disabled ? -1 : 0}
                aria-invalid={!isValid}
                readOnly={readOnly}
            />
            <label className={classes} htmlFor={id}>
                <span className={`form-radio-checkbox__icon form-radio-checkbox__icon--${type}`}>
                    {type === 'checkbox' ? (
                        <BaseIcon>
                            <IconCheckmark />
                        </BaseIcon>
                    ) : null}
                </span>
                {label || subLabel ? (
                    <span className="flex--grow">
                        {label ? <span className="display--block text-label">{label}</span> : null}
                        {subLabel ? <span className="display--block text-metadata">{subLabel}</span> : null}
                    </span>
                ) : null}
                {metadata && metadata}
            </label>
        </Fragment>
    );
};

RadioCheckboxBlock.defaultProps = {
    type: 'radio',
    className: '',
    label: undefined,
    metadata: undefined,
    subLabel: undefined,
    defaultChecked: undefined,
    checked: undefined,
    onChange: undefined,
    isValid: true,
    required: 0,
    disabled: false,
    value: undefined,
    readOnly: undefined,
};

RadioCheckboxBlock.propTypes = {
    /** Id of the field to be used with html For attribute. */
    id: PropTypes.string.isRequired,
    /** The post name of the radio. */
    name: PropTypes.string.isRequired,
    /** The type of input, checkbox or radio. */
    type: PropTypes.oneOf(['radio', 'checkbox']),
    /** Extra classes that can be added. */
    className: PropTypes.string,
    /** Label text seen by the user. */
    label: PropTypes.node,
    /** Optional metadata content - e.g. a sales tag or saving price - seen by the user. */
    metadata: PropTypes.node,
    /** Text seen by the user that goes underneath the main label text. */
    subLabel: PropTypes.node,
    /** Value of the radio option. */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** Weather the radio is checked without state. */
    defaultChecked: PropTypes.bool,
    /** Whether the radio is checked. */
    checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    /** Function to run on radio input change. */
    onChange: PropTypes.func,
    /** If the radio has an invalid value / error. */
    isValid: PropTypes.bool,
    /** required bool. */
    required: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    /** If the radio should be disabled. */
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
};

export default RadioCheckboxBlock;
