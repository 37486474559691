import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { variantShape } from '../../propTypes/treatmentShape';
import getDefaultVariant from '../helpers/getDefaultVariant';

import FieldWrapper from '../../../Forms/FieldWrapper/FieldWrapper';
import RadioCheckboxBlock from '../../../Forms/Inputs/RadioCheckboxBlock/RadioCheckboxBlock';

// TODO: Need to add aria-role="radiogroup" to multiple radio fields with one label.

const VariantSelector = ({ selectedVariant, variants, onChange }) => {
    /**
     * Gets the default variant by its "default" boolean attribute, else, by the first item in the
     * array. Set the value when a default is found.
     */
    useEffect(() => {
        const defaultVariant = getDefaultVariant(variants);
        onChange(defaultVariant);
    }, []);

    if (!selectedVariant || !variants || variants.length < 2) return null;

    const isOdonly = selectedVariant?.type.toUpperCase() === 'ODONLY';

    const ms = Date.now();

    return (
        <div className="treatment-selector__variant">
            <FieldWrapper id={`form-field--variant-${variants[0].id}-${ms}`} label={isOdonly ? 'Treatment options' : 'Treatment'}>
                {variants.map((variant) => {
                    const checked = selectedVariant.id.toString() === variant.id.toString();

                    return (
                        <RadioCheckboxBlock
                            key={variant.id}
                            id={`form-field--variant-${variant.id}-${ms}`}
                            label={variant.name}
                            name="form-field--variant"
                            onChange={() => onChange(variant)}
                            checked={checked}
                        />
                    );
                })}
            </FieldWrapper>
        </div>
    );
};

VariantSelector.defaultProps = {
    selectedVariant: undefined,
    variants: undefined,
};

VariantSelector.propTypes = {
    selectedVariant: variantShape,
    variants: PropTypes.arrayOf(variantShape),
    onChange: PropTypes.func.isRequired,
};

export default VariantSelector;
