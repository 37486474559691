/* eslint-disable camelcase */
import get from 'lodash.get';

import { getAffiliateJwtDecoded } from '../../../../affiliate/helpers/getAffiliateJwt';
import formatProducts, { FormatProductsReturnType } from './helpers/formatProducts';
import formatFreshDataLayer, { FormatFreshDataLayerReturnType } from './helpers/formatFreshDataLayer';
import NextDelivery from '../../../../../types/api/delivery/NextDelivery';
import { Product } from '../../../../../types/api/products/Product';

type GetTreatmentImage = (reference: number) => string;
type GetTreatmentDescription = (reference: number) => string;

interface ReturnType {
    ecommerce: {
        detail: {
            products: FormatProductsReturnType[];
        };
    };
    freshDataLayer?: FormatFreshDataLayerReturnType;
}

/**
 * Formats the analytics data for when a user lands on a product page.
 * @param {object} treatment - The treatment shown on the product page.
 * @param {object} deliveryData - The delivery data for the treatment.
 * @param {boolean} isLoggedIn - If there is a patient logged in.
 * @param {function} getTreatmentImage - Gets a treatments image.
 * @param {function} getTreatmentDescription - Gets a treatments description.
 * @returns {object}
 */
const formatProductPageAnalytics = (
    treatment: Product,
    deliveryData: NextDelivery,
    isLoggedIn: boolean,
    getTreatmentImage: GetTreatmentImage,
    getTreatmentDescription: GetTreatmentDescription
): ReturnType => {
    const affiliateCode = get(getAffiliateJwtDecoded({}), 'affiliate_code') || '';

    const dataToPush: ReturnType = {
        ecommerce: {
            detail: {
                products: formatProducts(treatment, isLoggedIn, affiliateCode),
            },
        },
    };

    const freshDataLayer: FormatFreshDataLayerReturnType = formatFreshDataLayer(
        treatment,
        deliveryData,
        getTreatmentImage,
        getTreatmentDescription
    );

    if (freshDataLayer) {
        dataToPush.freshDataLayer = freshDataLayer;
    }

    return dataToPush;
};

export default formatProductPageAnalytics;
