import get from 'lodash.get';

/* eslint-disable camelcase */
interface TreatmentParam {
    out_of_stock_message: string;
    out_of_stock_button_text: string;
    out_of_stock_button_url: string;
}
/* eslint-enable camelcase */

interface ReturnType {
    message: string;
    buttonText: string;
    buttonUrl: string;
}

/**
 * Gets the out of stock message details from the treatments response and formats with fallbacks.
 * @param {TreatmentParam} treatment - The treatment object from the API response.
 * @param {string} conditionSlug - The slug of the treatments condition.
 * @returns {ReturnType}
 */
const formatOutOfStockMessage = (treatment: TreatmentParam, conditionSlug: string): ReturnType => ({
    message: get(treatment, 'out_of_stock_message') || '',
    buttonText: get(treatment, 'out_of_stock_button_text') || 'Find alternatives',
    buttonUrl: get(treatment, 'out_of_stock_button_url') || `/${conditionSlug}`,
});

export default formatOutOfStockMessage;
