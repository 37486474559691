import React from 'react';
import PropTypes from 'prop-types';

import BaseIcon from '@components/_ui/_blocks/Icons/BaseIcon';
import IconChevronDown from '@components/_ui/_blocks/Icons/Navigation/IconChevronDown';
import IconLock from '@components/_ui/_blocks/Icons/IconLock';

const SelectInput = ({
    id,
    name,
    value,
    defaultValue,
    onChange,
    required,
    options,
    disabled,
    isLocked,
    isValid,
    placeholder,
    className,
}) => (
    <div className={`form-select ${className}`}>
        <select
            id={id && id}
            name={name}
            required={required}
            defaultValue={defaultValue}
            value={value}
            className={`form-field ${!isValid ? 'form-field--error' : ''} ${
                isLocked || disabled ? 'form-field--read-only' : ''
            } form-select__input`}
            onBlur={onChange}
            onChange={onChange}
            disabled={disabled}
            data-lpignore={!!(disabled || isLocked)}
        >
            {placeholder ? (
                <option value="" disabled>
                    {placeholder}
                </option>
            ) : null}
            {options &&
                options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.text}
                    </option>
                ))}
        </select>
        {isLocked ? (
            <BaseIcon className="form-field__icon">
                <IconLock />
            </BaseIcon>
        ) : (
            <BaseIcon className="form-select__icon">
                <IconChevronDown />
            </BaseIcon>
        )}
    </div>
);

SelectInput.defaultProps = {
    id: undefined,
    defaultValue: undefined,
    onChange: undefined,
    required: undefined,
    options: [],
    disabled: false,
    isLocked: false,
    isValid: true,
    placeholder: undefined,
    className: '',
};

SelectInput.propTypes = {
    id: PropTypes.string,
    /** The post name of the select. */
    name: PropTypes.string.isRequired,
    /** Value of the select option. */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    /** the select's default value. */
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** Function to run on select input change. */
    onChange: PropTypes.func,
    /** required bool. */
    required: PropTypes.bool,
    /** options of the seelct input */
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        })
    ),
    /** Adds classes if the input is disabled. */
    disabled: PropTypes.bool,
    /** If the field is locked, add class. */
    isLocked: PropTypes.bool,
    /** If the field value is valid, if not add class. */
    isValid: PropTypes.bool,
    /** If the field has a placeholder. */
    placeholder: PropTypes.string,
    className: PropTypes.string,
};

export default SelectInput;
