import { ADVANCED_ECOM_ENABLED, isBrowser, TRACKING_ENABLED, LOG_TRACKING } from '../../../config/config'; // eslint-disable-line object-curly-newline
import formatConsultationAnalytics from './formatters/formatConsultationAnalytics';
import formatLoginAnalytics from './formatters/formatLoginAnalytics';
import formatOrderCompleteAnalytics from './formatters/formatOrderCompleteAnalytics/formatOrderCompleteAnalytics';
import formatProductPageAnalytics from './formatters/formatProductPageAnalytics/formatProductPageAnalytics';
import formatAddToBasketAnalytics from './formatters/formatAddToBasketAnalytics';
import formatCheckoutDeliveryAnalytics from './formatters/formatCheckoutDeliveryAnalytics';
import formatPaymentSelectAnalytics from './formatters/formatPaymentSelectAnalytics';
import formatSearchAnalytics from './formatters/formatSearchAnalytics';

// A list of google analytics events.
export enum gaEvents {
    UserLogin = 'userLogin',
    ProductPage = 'eec.detail',
    ConsultationRepeat = 'repeatPurchaseStart',
    ConsultationStart = 'consultationStart',
    ConsultationComplete = 'consultationComplete',
    MedicalQuestionsStart = 'medicalQuestionsStart',
    MedicalQuestionsComplete = 'medicalQuestionsComplete',
    PatientProfileStart = 'patientProfileStart',
    PatientProfileComplete = 'patientProfileComplete',
    RepeatPurchaseStart = 'repeatPurchaseStart',
    RepeatPurchaseComplete = 'repeatPurchaseComplete',
    AddToBasket = 'eec.add',
    CheckoutDelivery = 'eec.checkout',
    PaymentSelect = 'paymentSelect',
    OrderComplete = 'eec.purchase',
    Search = 'search',
    SortUsage = 'sort_usage',
    FilterAdd = 'filter_add',
    FilterRemove = 'filter_remove',
    ZeroResults = 'zero_results',
    ProductClick = 'eec.productClick',
}

// A list of google analytics formatters to manipulate the data required to send an event.
export const gaFormatters = {
    userLogin: formatLoginAnalytics,
    productPage: formatProductPageAnalytics,
    consultation: formatConsultationAnalytics,
    addToBasket: formatAddToBasketAnalytics,
    checkoutDelivery: formatCheckoutDeliveryAnalytics,
    paymentSelect: formatPaymentSelectAnalytics,
    orderComplete: formatOrderCompleteAnalytics,
    search: formatSearchAnalytics,
};

const googleAnalyticsEnabled = !!(isBrowser() && TRACKING_ENABLED === true && ADVANCED_ECOM_ENABLED === true);

/**
 * Sends a google analytics event.
 * @param {gaEvents} event - One value of googleEvents.
 * @param {T} data - Data being sent.
 * @returns {void}
 */
const dispatchGoogleAnalyticsEvent = <T>(event: gaEvents, data: T): void => {
    if (!Object.values(gaEvents).includes(event)) {
        console.error(`${event} is not a supported analytics event.`);
        return;
    }

    const analyticsDataToSend = {
        ...data,
        event,
    };

    // If log tracking in the .env is enabled.
    if (LOG_TRACKING) {
        console.log('Google Analytics Event:', analyticsDataToSend);
    }

    if (googleAnalyticsEnabled === false) {
        return;
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(analyticsDataToSend);
};

export default dispatchGoogleAnalyticsEvent;
