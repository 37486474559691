import React from 'react';
import PropTypes from 'prop-types';
import SSRLazyLoadWrap from '@components/SSRLazyLoadWrap';

const Avatar = ({ path, alt, className }) => (
    <div className={`avatar ${className}`}>
        <SSRLazyLoadWrap once>
            <img src={path || '/favicon.ico'} alt={alt} />
        </SSRLazyLoadWrap>
    </div>
);

export default Avatar;

Avatar.defaultProps = {
    className: '',
    alt: 'The Independent Pharmacy staff member',
    path: undefined,
};

Avatar.propTypes = {
    /** Class that gets added to the avatar div. */
    className: PropTypes.string,
    /** String of path to the avatar image. */
    path: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    /** The alt tag for the avatar image. */
    alt: PropTypes.string,
};
