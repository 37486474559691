import get from 'lodash.get';

import { QUANTITY_TYPES } from '../../../../constants';
import sendSentryMessage from '../../../../helpers/sendSentryMessage';

/**
 * Gets the latest treatment details for a treatment being reordered using the reordered treatment and the treatment requested from the API.
 * @param {object} treatmentToReorder - The treatment that is being reordered (supplied via patient/me request).
 * @param {object} treatment - The treatment returned from the API.
 * @returns {null|object}
 */
const getLatestTreatmentReorderDetails = (treatmentToReorder, treatment) => {
    const { reference, quantity: q } = treatmentToReorder;

    const reorderVariant = treatment.variants.find(({ id }) => id === reference);

    if (typeof reorderVariant === 'undefined') {
        // If no variant is found
        // You can also log the error to an error reporting service
        sendSentryMessage(
            `Extra Info: When trying to get the refill information, No variant has been found. for ref: ${reference}. type of ref - ${typeof reference}. treatmentid: ${
                treatment.id
            }. typeof treatment: ${typeof treatment}`,
            (scope) => {
                scope.setExtra('reference', `info: ${reference}`);
                scope.setExtra('treatmentToReorder', `info: ${JSON.stringify(treatmentToReorder)}`);
                scope.setExtra('treatment', `info: ${JSON.stringify(treatment)}`);
            }
        );

        throw new Error('When trying to get the refill information, No variant has been found.');
    }

    let reorderQuantity =
        reorderVariant.quantity_type.toUpperCase() === QUANTITY_TYPES.timed
            ? { ...get(reorderVariant, 'quantities[0]', {}), quantity: q }
            : reorderVariant.quantities.find(({ quantity }) => quantity === q);

    if (typeof reorderQuantity === 'undefined') {
        // If no quantity is found, then we just return an empty object to pass to the view.
        reorderQuantity = {};
    }

    return {
        reference,
        treatment: reorderVariant.name,
        ...reorderQuantity,
    };
};

export default getLatestTreatmentReorderDetails;
