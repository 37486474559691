import React, { useEffect } from 'react';
import loadable from '@loadable/component';

import { useBasketContext } from '@data/context/basketContext/basketContext';

import Loading from '../_ui/_blocks/Loading/Loading';
import Flyout from '../_ui/Flyout';

const BasketPreview = loadable(() => import('./BasketPreview/BasketPreview'));

/**
 * @param {*} props
 */
const Basket = () => {
    const { basketIsOpen, setBasketIsOpen, initialLoadComplete } = useBasketContext();

    const handleBasketClose = () => setBasketIsOpen(false);

    /**
     * On unmount of the basket sidebar, close it.
     */
    useEffect(
        () => () => {
            handleBasketClose();
        },
        []
    );

    return (
        <Flyout className="flyout--basket" isOpen={basketIsOpen} onClose={handleBasketClose} title="Your basket">
            <div data-testid="dropdown-menu" className="flyout__content--basket">
                <Loading isLoading={!initialLoadComplete}>
                    <BasketPreview />
                </Loading>
            </div>
        </Flyout>
    );
};

export default Basket;
