import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';

import Footer from '../../components/Footer/Footer';
import HeaderMinimal from '../../components/Navigation/HeaderMinimal';
import SessionActivity from '../../components/Authentication/SessionActivity/SessionActivity';
import AffiliateBanner from '../../modules/affiliate/components/AffiliateBanner';
import SiteBanner from '../../modules/siteBanner/components/SiteBanner/SiteBanner';

const FontEmbed = loadable(() => import('@components/_ui/FontEmbed'));
const CookiesNotification = loadable(() => import('@components/CookiesNotification'));

/**
 * Minimal layout component form pages like maintenance.
 * @param {object} props
 * @returns {ReactElement}
 */
const MinimalLayout = ({ children }) => (
    <Fragment>
        <FontEmbed />
        <AffiliateBanner />
        <HeaderMinimal />
        <SiteBanner />
        <main>{children}</main>
        <Footer />
        <CookiesNotification />
        <SessionActivity />
    </Fragment>
);

MinimalLayout.propTypes = {
    /**
     * The page content components.
     */
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element), PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

export default MinimalLayout;
