import get from 'lodash.get';
import { getAffiliateJwtDecoded } from './getAffiliateJwt';

/**
 * Checks to see if treatment is in the affiliate treatment scope.
 * @param {number} treatmentId - The treatment ID to check.
 * @returns {boolean}
 */
export const affiliateInScope = (treatmentId) => {
    const decodedJwt = getAffiliateJwtDecoded({});
    const scope = get(decodedJwt, 'treatment_scope', []);

    if (scope.includes('*')) {
        return true;
    }

    return scope.findIndex((id) => id.trim().toString() === treatmentId.toString()) > -1;
};
