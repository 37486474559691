import React from 'react';
import loadable from '@loadable/component';

import { useConfirmationContext } from '../../../data/context/confirmationContext';

import Overlay from '../_blocks/Overlay';

const ConfirmationDefault = loadable(() => import('./ConfirmationTypes/ConfirmationDefault'));
const ConfirmationAlert = loadable(() => import('./ConfirmationTypes/ConfirmationAlert'));

const componentIndex = {
    default: ConfirmationDefault,
    alert: ConfirmationAlert,
};

/** We have a few confirmation styles we need with different markups, here we use a component index and load a new file based upon type passed in */
const Confirmation = () => {
    const { isConfirmationOpen, confirmationAttributes, reset } = useConfirmationContext();

    const ConfirmationComponentToRender = componentIndex[confirmationAttributes.type || 'default'];

    return (
        <Overlay show={isConfirmationOpen} fixedToViewport={confirmationAttributes.fixedToViewport} className="confirmation" onHide={reset}>
            <ConfirmationComponentToRender {...confirmationAttributes} type={confirmationAttributes.type} />
        </Overlay>
    );
};

// <Confirmation
//     isOpen={isConfirmationOpen}
//     {...confirmationAttributes}
//     onClose={() => setIsConfirmationOpen(false)}
//     controls={confirmationAttributes.controls && confirmationAttributes.controls}
//     onConfirmationClosed={reset}
// >
//     {confirmationAttributes.children}
// </Confirmation>

export default Confirmation;
