import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { useBasketContext } from '../../data/context/basketContext/basketContext';

import BaseIcon from '../_ui/_blocks/Icons/BaseIcon';
import IconBasket from '../_ui/_blocks/Icons/IconBasket';
import NumberIncrement from '../_ui/_blocks/NumberIncrement/NumberIncrement';
import ImagePreload from '../_ui/_blocks/ImagePreload';
import AccountDropdown from '../_ui/Account/AccountDropdown';

/**
 * A basic header used in the AffiliateLayout component.
 * @param {object} props
 * @returns {ReactElement}
 */
const HeaderAffiliate = ({ className }) => {
    const { basket, setBasketIsOpen } = useBasketContext();

    return (
        <header className={`header ${className}`}>
            <div className="header__top header__top--affiliate">
                <Link
                    to="/"
                    className="header__logo"
                    title="The Independent Pharmacy homepage"
                    aria-label="The Independent Pharmacy homepage"
                >
                    <ImagePreload src="/images/logo.svg" alt="The Independent Pharmacy" width="125" className="display--block" />
                </Link>
                <div className="header__buttons">
                    <AccountDropdown disableAccountDropdownOnMobile />
                    <button
                        type="button"
                        className="header__button header__button--basket"
                        aria-label="Open your basket"
                        title="Open your basket"
                        onClick={(e) => {
                            e.preventDefault();
                            setBasketIsOpen((s) => !s);
                        }}
                    >
                        <span className="text-label text-color--midnight">Basket</span>
                        <BaseIcon className="icon--small icon--midnight">
                            <IconBasket />
                        </BaseIcon>
                        {basket && basket.items && basket.items.length ? (
                            <div className="header__basket-count pill">
                                <NumberIncrement number={basket.items.length} />
                            </div>
                        ) : null}
                    </button>
                </div>
            </div>
        </header>
    );
};

HeaderAffiliate.defaultProps = {
    className: '',
};

HeaderAffiliate.propTypes = {
    /**
     * Extra class names that can be added.
     */
    className: PropTypes.string,
};

export default HeaderAffiliate;
