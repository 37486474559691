import React from 'react';
import PropTypes from 'prop-types';

import sendSentryMessage from '../../helpers/sendSentryMessage';
import IconCross from '../_ui/_blocks/Icons/Navigation/IconCross';
import BaseIcon from '../_ui/_blocks/Icons/BaseIcon';
import Notification from '../_ui/Notification';

export default class ModalErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, errorMessage: null };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, errorMessage: error.message };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        sendSentryMessage(error, (scope) => {
            scope.setExtra('errorInfo', errorInfo);
        });
    }

    render() {
        const { errorMessage, hasError } = this.state;
        const { children, onClose } = this.props;

        // You can render any custom fallback UI
        if (hasError) {
            return (
                <div className="card flow--large dialog dialog--small">
                    <header className="flow--small">
                        <div className="flex flex--space-between">
                            <h3 className="text-title-six">Error</h3>
                            <button type="button" className="dialog__close" onClick={onClose} title="Close treatment refill">
                                <BaseIcon className="icon--midnight icon--small">
                                    <IconCross />
                                </BaseIcon>
                            </button>
                        </div>
                    </header>
                    <Notification className="flow--small notification--small" type="info" heading="There was an unexpected error">
                        <p className="text-metadata text-color--slate">{errorMessage} Please contact support.</p>
                    </Notification>
                    <div className="breathe text-align--right">
                        <button type="button" className="button" onClick={onClose}>
                            Close
                        </button>
                    </div>
                </div>
            );
        }

        return children;
    }
}

ModalErrorBoundary.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    onClose: PropTypes.func.isRequired,
};
