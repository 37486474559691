import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import Flash from '@components/_ui/_blocks/Flash';

import { useCookieWatcher } from '@helpers/cookie';
import { DEFAULT_FLASH_MESSAGE_KEY, FLASH_EXPIRES_IN } from './config/config';

const FlashMessage = ({ cookieKey }) => {
    const [message, forceCookieRemove] = useCookieWatcher(cookieKey);

    const timeout = useRef();

    useEffect(() => {
        timeout.current = setTimeout(() => forceCookieRemove(), FLASH_EXPIRES_IN * 60 * 1000);

        return () => {
            if (timeout.current) clearTimeout(timeout.current);
        };
    }, []);

    const handleClose = () => {
        forceCookieRemove();
    };

    return message ? (
        <div className="session-flash">
            <Flash type="warning" message={message} onClose={handleClose} />
        </div>
    ) : null;
};

FlashMessage.defaultProps = {
    cookieKey: DEFAULT_FLASH_MESSAGE_KEY,
};

FlashMessage.propTypes = {
    cookieKey: PropTypes.string,
};

export default FlashMessage;
