/**
 * Takes an array of product names and formats them into "name1, name2 and name3", "name1 and name2" or
 * "name1" depending on the number of names.
 * @param {array} treatmentNames - Array of product names as strings.
 * @returns {string}
 */
const formatBasketBlockedByTreatmentsNames = (treatmentNames) => {
    if (treatmentNames.length === 1) {
        return treatmentNames[0];
    }

    const lastName = treatmentNames.slice(-1);
    const otherNames = treatmentNames.slice(0, treatmentNames.length - 1);

    return `${otherNames.join(', ')} and ${lastName}`;
};

export default formatBasketBlockedByTreatmentsNames;
