import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'gatsby';

import BaseIcon from '@components/_ui/_blocks/Icons/BaseIcon';
import IconChevronRight from '@components/_ui/_blocks/Icons/Navigation/IconChevronRight';
import CraftMenuLink from '../../../CraftMenuLink/CraftMenuLink';

const MegamenuTreatments = ({ nodes, conditions, className }) => {
    const timeout = useRef();

    const handleButtonClick = () => {
        document.body.classList.add('_js-nav-megamenu-cancel');
        timeout.current = setTimeout(() => {
            document.body.classList.remove('_js-nav-megamenu-cancel');
        }, [500]);
    };

    useEffect(
        () => () => {
            // On unmount clear timeout
            if (timeout.current) {
                document.body.classList.remove('_js-nav-megamenu-cancel');
                clearTimeout(timeout.current);
            }
        },
        []
    );

    return (
        <div className={`nav-megamenu nav-megamenu--expanded ${className}`}>
            <div className="grid-x grid-margin-x nav-megamenu__section">
                <div className="cell nav-megamenu__header">
                    <span className="text-title-three nav-megamenu__title">Popular Treatments</span>
                    <div className="breathe--large">
                        <Link to="/treatments" className="button button--primary" onClick={handleButtonClick}>
                            View all treatments
                        </Link>
                        <Link to="/conditions" className="button button--secondary" onClick={handleButtonClick}>
                            View all conditions
                        </Link>
                    </div>
                </div>
                {nodes.map((node) => {
                    const condition = conditions.find((c) => c.slug === node.element.slug);
                    return (
                        <div className="cell medium-4 xlarge-3" key={node.id}>
                            <CraftMenuLink title={`See all ${node.title} Treatments`} menuNode={node} className="nav-megamenu__condition">
                                <span className="text-label">
                                    {node.title}&nbsp;
                                    <span className="text-metadata text-color--slate">({condition ? condition.treatmentCount : 0})</span>
                                </span>
                                <BaseIcon className="nav-megamenu__chevron">
                                    <IconChevronRight />
                                </BaseIcon>
                            </CraftMenuLink>
                            {node.children && (
                                <ul className="nav-megamenu__treatments">
                                    {node.children.map((child) => (
                                        <li key={child.id}>
                                            <CraftMenuLink
                                                title={child.title}
                                                menuNode={child}
                                                className="nav-megamenu__treatment text-metadata"
                                            >
                                                {child.title}
                                            </CraftMenuLink>
                                        </li>
                                    ))}
                                </ul>
                            )}

                            <CraftMenuLink
                                title={`See all ${node.title} Treatments`}
                                menuNode={node}
                                className="nav-megamenu__view-all text-link"
                            >
                                View All
                            </CraftMenuLink>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

MegamenuTreatments.defaultProps = {
    nodes: [],
    conditions: [],
    className: '',
};

MegamenuTreatments.propTypes = {
    /** The navigation tree to be rendered */
    nodes: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            title: PropTypes.string,
            level: PropTypes.string,
            url: PropTypes.string,
            parent: PropTypes.shape({
                id: PropTypes.string,
            }),
        })
    ),
    conditions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            treatmentCount: PropTypes.number,
        })
    ),
    className: PropTypes.string,
};

export default MegamenuTreatments;
