import React from 'react';
import PropTypes from 'prop-types';

import { Location } from '@reach/router';
import { Link } from 'gatsby';
import { genericHashLink } from 'react-router-hash-link';
import { useAccountNavigationContext } from '@data/context/accountContext/accountNavigationContext';
import { useNavigationContext } from '@data/context/navigationContext';

import BaseIcon from '@components/_ui/_blocks/Icons/BaseIcon';
import IconArrowLeft from '@components/_ui/_blocks/Icons/Navigation/IconArrowLeft';
import IconExit from '@components/_ui/_blocks/Icons/IconExit';
import IconIdCard from '@components/_ui/_blocks/Icons/IconIdCard';
import IconOrder from '@components/_ui/_blocks/Icons/IconOrder';
import IconPaymentMethods from '@components/_ui/_blocks/Icons/IconPaymentMethods';
import IconAccount from '@components/_ui/_blocks/Icons/IconAccount';
import IconSettings from '@components/_ui/_blocks/Icons/IconSettings';
import Flyout from '@components/_ui/Flyout';
import { useAuthContext } from '@data/context/authContext';
import useWindowSizeCheck from '../../hooks/useWindowSizeCheck';

const AccountNavigation = ({ fixSidebar }) => {
    const ScrollLink = genericHashLink(Link);

    const { patientName, logout } = useAuthContext();
    const { setSidebarNavIsOpen } = useNavigationContext();
    const { accountNavIsOpen, setAccountNavIsOpen } = useAccountNavigationContext();

    const [isGreaterThanNineSixty] = useWindowSizeCheck(960);
    const sidebarIsFixed = fixSidebar && isGreaterThanNineSixty;

    const onLinkClick = () => setAccountNavIsOpen(false);

    const handleBackToMenu = () => {
        setSidebarNavIsOpen(true);
        setAccountNavIsOpen(false);
    };

    const handleLogout = () => logout();

    return (
        <Location>
            {({ location }) => (
                <Flyout
                    isOpen={accountNavIsOpen || sidebarIsFixed}
                    disableOverlay={sidebarIsFixed}
                    className="nav-sidebar"
                    onClose={() => setAccountNavIsOpen(false)}
                    title={
                        <button type="button" onClick={handleBackToMenu} className="flyout__back">
                            <BaseIcon className="icon--xsmall icon--default">
                                <IconArrowLeft />
                            </BaseIcon>
                            <span className="text-smallcaps">Back to menu</span>
                        </button>
                    }
                >
                    <nav>
                        <div className="nav-sidebar__item nav-account__welcome">
                            <div className="nav-sidebar__link">
                                <h4 className="text-title-six ellipsis">{`Hello ${patientName || 'user'}`}</h4>
                            </div>
                        </div>
                        <div className={`nav-sidebar__item ${location.pathname === '/account' ? '_is-active' : ''}`}>
                            <Link to="/account" className="nav-sidebar__link" title="Dashboard" onClick={onLinkClick}>
                                <BaseIcon className="icon--medium icon--slate">
                                    <IconAccount />
                                </BaseIcon>
                                <span className="text-heading">Dashboard</span>
                            </Link>
                        </div>
                        <div className={`nav-sidebar__item ${location.pathname.indexOf('/account/orders') > -1 ? '_is-active' : ''}`}>
                            <Link to="/account/orders" className="nav-sidebar__link" title="Orders" onClick={onLinkClick}>
                                <BaseIcon className="icon--medium icon--slate icon--sunrise">
                                    <IconOrder />
                                </BaseIcon>
                                <span className="text-heading">Orders</span>
                            </Link>
                            <ul className="nav-sidebar__subitem">
                                <li>
                                    <ScrollLink
                                        to="/account/orders#order-history"
                                        activeClassName="is-active"
                                        partiallyActive
                                        title="Order history"
                                        onClick={onLinkClick}
                                    >
                                        <span className="text-metadata text-color--slate">View your order history</span>
                                    </ScrollLink>
                                </li>
                                <li>
                                    <Link
                                        to="/account/orders/treatment-reorders"
                                        activeClassName="is-active"
                                        partiallyActive
                                        title="Treatment reorders"
                                        onClick={onLinkClick}
                                    >
                                        <span className="text-metadata text-color--slate">Reorder a treatment</span>
                                    </Link>
                                </li>
                                <li>
                                    <ScrollLink
                                        to="/account/orders#saved-baskets"
                                        activeClassName="is-active"
                                        partiallyActive
                                        title="Saved baskets"
                                        onClick={onLinkClick}
                                    >
                                        <span className="text-metadata text-color--slate">Load a saved basket</span>
                                    </ScrollLink>
                                </li>
                            </ul>
                        </div>
                        <div
                            className={`nav-sidebar__item ${
                                location.pathname.indexOf('/account/delivery-addresses') > -1 ||
                                location.pathname.indexOf('/account/saved-payment-methods') > -1
                                    ? '_is-active'
                                    : ''
                            }`}
                        >
                            <Link
                                to="/account/delivery-addresses"
                                className="nav-sidebar__link"
                                title="Billing & delivery"
                                onClick={onLinkClick}
                            >
                                <BaseIcon className="icon--medium icon--slate icon--sunrise">
                                    <IconPaymentMethods />
                                </BaseIcon>
                                <span className="text-heading">Billing & delivery</span>
                            </Link>
                            <ul className="nav-sidebar__subitem">
                                <li>
                                    <Link
                                        to="/account/delivery-addresses"
                                        activeClassName="_is-active"
                                        title="Delivery addresses"
                                        onClick={onLinkClick}
                                    >
                                        <span className="text-metadata text-color--slate">Delivery addresses</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/account/saved-payment-methods"
                                        activeClassName="_is-active"
                                        title="Payment methods"
                                        onClick={onLinkClick}
                                    >
                                        <span className="text-metadata text-color--slate">Payment methods</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div
                            className={`nav-sidebar__item ${
                                location.pathname.indexOf('/account/personal-medical-record') > -1 ? '_is-active' : ''
                            }`}
                        >
                            <Link
                                to="/account/personal-medical-record"
                                className="nav-sidebar__link"
                                title="Personal Medical Record"
                                onClick={onLinkClick}
                            >
                                <BaseIcon className="icon--medium icon--slate ">
                                    <IconIdCard />
                                </BaseIcon>
                                <span className="text-heading">Personal Medical Record</span>
                            </Link>
                            <ul className="nav-sidebar__subitem">
                                <li>
                                    <ScrollLink
                                        to="/account/personal-medical-record#personal-details"
                                        activeClassName="_is-active"
                                        title="Personal details"
                                        onClick={onLinkClick}
                                    >
                                        <span className="text-metadata text-color--slate">Personal details</span>
                                    </ScrollLink>
                                </li>
                                <li>
                                    <ScrollLink
                                        to="/account/personal-medical-record#consultations"
                                        activeClassName="_is-active"
                                        title="Consultations"
                                        onClick={onLinkClick}
                                    >
                                        <span className="text-metadata text-color--slate">Consultations</span>
                                    </ScrollLink>
                                </li>
                                <li>
                                    <ScrollLink
                                        to="/account/personal-medical-record#test-results"
                                        activeClassName="_is-active"
                                        title="Test results"
                                        onClick={onLinkClick}
                                    >
                                        <span className="text-metadata text-color--slate">Test results</span>
                                    </ScrollLink>
                                </li>
                                <li>
                                    <ScrollLink
                                        to="/account/personal-medical-record#prescriptions"
                                        activeClassName="_is-active"
                                        title="Prescriptions"
                                        onClick={onLinkClick}
                                    >
                                        <span className="text-metadata text-color--slate">Prescription Requests</span>
                                    </ScrollLink>
                                </li>
                            </ul>
                        </div>
                        <div className={`nav-sidebar__item ${location.pathname.indexOf('/account/settings') > -1 ? '_is-active' : ''}`}>
                            <Link to="/account/settings" className="nav-sidebar__link" title="Settings" onClick={onLinkClick}>
                                <BaseIcon className="icon--medium icon--slate">
                                    <IconSettings />
                                </BaseIcon>
                                <span className="text-heading">Edit Details</span>
                            </Link>
                            <ul className="nav-sidebar__subitem">
                                <li>
                                    <Link
                                        to="/account/settings"
                                        activeClassName="_is-active"
                                        title="Personal details"
                                        onClick={onLinkClick}
                                    >
                                        <span className="text-metadata text-color--slate">Personal details</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/account/settings/weight-and-height"
                                        activeClassName="_is-active"
                                        title="Weight &amp; height"
                                        onClick={onLinkClick}
                                    >
                                        <span className="text-metadata text-color--slate">Weight &amp; height</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/account/settings/password"
                                        activeClassName="_is-active"
                                        title="Update password"
                                        onClick={onLinkClick}
                                    >
                                        <span className="text-metadata text-color--slate">Update password</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="nav-sidebar__item">
                            <button type="button" className="nav-sidebar__button" title="Sign out of this account" onClick={handleLogout}>
                                <BaseIcon className="icon--medium icon--slate">
                                    <IconExit />
                                </BaseIcon>
                                <span className="text-heading">Sign out</span>
                            </button>
                        </div>
                    </nav>
                </Flyout>
            )}
        </Location>
    );
};

AccountNavigation.defaultProps = {
    fixSidebar: true,
};
AccountNavigation.propTypes = {
    fixSidebar: PropTypes.bool,
};

export default AccountNavigation;
