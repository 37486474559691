import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';

import { treatmentShape } from '../propTypes/treatmentShape';
import { QUANTITY_TYPES } from '../../../constants';

import Notification from '../../_ui/Notification';
import VariantSelector from './VariantSelector/VariantSelector';
import QuantitySelector from './QuantitySelector/QuantitySelector';
import DeliveryPricing from './DeliveryPricing/DeliveryPricing';

const TreatmentSelector = ({ treatment, onChange, isValid, selectedTreatment, showFreeDeliveryNotice, enableQuantityListing }) => {
    /**
     * Updates the formState varaible using setFormState.
     * @param {string} name - Name of the formState property to change.
     * @param {object} value - The value for the named property.
     */
    const handleQuantityChange = (quantity) => onChange((state) => ({ ...state, quantity }));

    /**
     * Updates the formState to the new selected variant and resets the quantity to the new default.
     * @param {object} variant - The new variant to update the formState to.
     */
    const handleVariantChange = (variant) => onChange({ variant, quantity: null });

    let price = '-';
    if (get(selectedTreatment, 'quantity.price')) {
        price = `£${(get(selectedTreatment, 'quantity.price') / 100).toFixed(2)}`;
    }

    if (!treatment) {
        return null;
    }

    const isOdOnly = selectedTreatment?.variant?.type?.toUpperCase() === 'ODONLY';
    const isTimedTreatment = selectedTreatment?.variant?.quantity_type.toUpperCase() === QUANTITY_TYPES.timed;

    // Removes any unavailable variants.
    const variants = treatment.variants.filter((variant) => variant.available);

    const numberOfAvailableQuantities = (get(selectedTreatment, 'variant.quantities') || []).length;

    const priceInPence = get(selectedTreatment, 'quantity.price') || 0;

    let showSubTotal = true;
    if (isOdOnly && enableQuantityListing && !isTimedTreatment) {
        showSubTotal = false;
    }

    if (!variants.length) {
        return (
            <Notification type="info" heading="Product unavailable" className="notification--small flow--small" fullwidthChildren>
                <p className="text-metadata">This product is currently unavailable.</p>
            </Notification>
        );
    }

    return (
        <Fragment>
            <VariantSelector selectedVariant={selectedTreatment.variant} variants={variants} onChange={handleVariantChange} />

            {!isValid && numberOfAvailableQuantities ? (
                <QuantitySelector
                    selectedVariant={selectedTreatment.variant}
                    selectedQuantity={get(selectedTreatment, 'quantity.quantity')}
                    onChange={handleQuantityChange}
                    enableQuantityListing={enableQuantityListing}
                />
            ) : null}

            {treatment && !isValid && numberOfAvailableQuantities ? (
                <Fragment>
                    {showFreeDeliveryNotice || !isOdOnly ? <hr className="hr hr--flush background-color--light" /> : null}
                    {showFreeDeliveryNotice ? <DeliveryPricing priceInPence={priceInPence} /> : null}
                    {showSubTotal ? (
                        <div className="flex gap-1 justify-between items-center">
                            <span className="text-090 font-bold text-sky-100">Subtotal</span>
                            <figure className="text-price text-price--700">{price}</figure>
                        </div>
                    ) : null}
                </Fragment>
            ) : null}
        </Fragment>
    );
};

TreatmentSelector.defaultProps = {
    treatment: undefined,
    onChange: () => null,
    showFreeDeliveryNotice: false,
    enableQuantityListing: false,
};

TreatmentSelector.propTypes = {
    treatment: treatmentShape,
    onChange: PropTypes.func,
    isValid: PropTypes.bool.isRequired,
    enableQuantityListing: PropTypes.bool,
    selectedTreatment: PropTypes.shape({
        variant: PropTypes.object,
        quantity: PropTypes.object,
    }).isRequired,
    showFreeDeliveryNotice: PropTypes.bool,
};

export default TreatmentSelector;
