import React, { Fragment } from 'react';
import get from 'lodash.get';

import { useBasketContext } from '../../../../data/context/basketContext/basketContext';

import BaseIcon from '../../../_ui/_blocks/Icons/BaseIcon';
import IconTruckTwoTone from '../../../_ui/_blocks/Icons/IconTruckTwoTone';

interface Props {
    priceInPence: number;
    isLoading: boolean;
}

const DeliveryPricing = ({ priceInPence, isLoading }: Props) => {
    const { basket } = useBasketContext();

    const totalOfBasketAndPrice = (get(basket, 'total') || 0) + priceInPence;

    return (
        <div className="flex breathe--medium">
            <BaseIcon className="icon--medium icon--sky flex--no-shrink">
                <IconTruckTwoTone />
            </BaseIcon>
            {!isLoading ? (
                <Fragment>
                    {totalOfBasketAndPrice > 4000 ? (
                        <span className="text-label">Includes free standard delivery</span>
                    ) : (
                        <span className="text-label">Free standard delivery on orders over £40</span>
                    )}
                </Fragment>
            ) : null}
        </div>
    );
};

export default DeliveryPricing;
