import classNames from 'classnames';

import * as styles from '../Typography.module.css';

export enum Tags {
    h1 = 'h1',
    h2 = 'h2',
    h3 = 'h3',
    h4 = 'h4',
    h5 = 'h5',
    h6 = 'h6',
    p = 'p',
    a = 'a',
    span = 'span',
    div = 'div',
    dt = 'dt',
    dd = 'dd',
    label = 'label',
    blockquote = 'blockquote',
    address = 'address',
    li = 'li',
}

export enum Colors {
    base = styles.baseColor, // We use a class for this one as text-base is the base font size, not colour.
    'secondary-100' = 'text-secondary-100',
    'secondary-200' = 'text-secondary-200',
    'secondary-300' = 'text-secondary-300',
    'secondary-500' = 'text-secondary-500',
    'secondary-600' = 'text-secondary-600',
    'secondary-800' = 'text-secondary-800',
    'tertiary-300' = 'text-tertiary-300',
    'tertiary-400' = 'text-tertiary-400',
    'error-400' = 'text-error-400',
    highlight = 'text-highlight',
    loud = 'text-loud',
    accent = 'text-accent',
    sky300 = 'text-sky-300',
    quiet = 'text-quiet',
    white = 'text-neutrals-white',
    black = 'text-neutrals-black',
}

export enum Variants {
    title = 'type-title',
    subtitle = 'type-subtitle',
    heading = 'type-heading',
    subheading = 'type-subheading',
    blockquote = 'type-blockquote',
    emphasis = 'type-emphasis',
    paragraph = 'type-paragraph',
    metadata = 'type-metadata',
    label = 'type-label',
    smallcaps = 'type-smallcaps',
}

export const LineHeights = {
    none: 'leading-none',
    '100': 'leading-100',
    '200': 'leading-200',
    '300': 'leading-300',
    '400': 'leading-400',
    '500': 'leading-500',
    '600': 'leading-600',
    '700': 'leading-700',
    '800': 'leading-800',
    '900': 'leading-900',
    '1000': 'leading-1000',
};

export enum Accents {
    underline = 'underline',
    italic = 'italic',
}

export const fontSizes = {
    '060': 'text-060',
    '070': 'text-070',
    '080': 'text-080',
    '090': 'text-090',
    '100': 'text-100',
    '200': 'text-200',
    '300': 'text-300',
    '400': 'text-400',
    '500': 'text-500',
    '600': 'text-600',
    '700': 'text-700',
};

enum Weights {
    // thin = 'font-thin',
    // extralight = 'font-thin',
    // light = 'font-light',
    // normal = 'font-normal',
    medium = 'font-medium',
    // semibold = 'font-semibold',
    bold = 'font-bold',
    // extrabold = 'font-extrabold',
    // black = 'font-black'
}

export interface UseTypographyOptions {
    as?: Tags | keyof typeof Tags;
    variant?: keyof typeof Variants;
    color?: keyof typeof Colors;
    accent?: keyof typeof Accents;
    fontSize?: keyof typeof fontSizes;
    lineHeight?: keyof typeof LineHeights;
    className?: string; // Use this sparingly or for layout purposes. Or you will die. Use the variant and color props instead plz.
    weight?: keyof typeof Weights;
}

const useTypography = ({
    variant = 'paragraph',
    color = 'base',
    className = '',
    fontSize,
    accent,
    lineHeight,
    weight,
}: UseTypographyOptions) => {
    const cn = classNames(
        variant ? Variants[variant] : '',
        color ? Colors[color] : '',
        accent ? Accents[accent] : '',
        fontSize ? fontSizes[fontSize] : '',
        lineHeight ? LineHeights[lineHeight] : '',
        weight ? Weights[weight] : '',
        className
    );

    return cn;
};

export default useTypography;
