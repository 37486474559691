/* eslint-disable react/button-has-type */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import BusyIcon from '@components/_ui/_blocks/BusyIcon';
import BaseIcon from '@components/_ui/_blocks/Icons/BaseIcon';
import IconStatusSuccess from '@components/_ui/_blocks/Icons/Status/IconStatusSuccess';

const ButtonBusy = ({ type, className, children, title, loadingText, successText, onClick, isBusy, isSuccess, disabled }) => {
    const [success, setSuccess] = useState(false);
    const timeout = useRef();
    useEffect(() => {
        setSuccess(isSuccess);
        if (isSuccess) {
            timeout.current = setTimeout(() => setSuccess(false), 2000);
        }
        return () => {
            if (timeout.current) clearTimeout(timeout.current);
        };
    }, [isSuccess]);

    return (
        <button
            className={`button button--busy ${isBusy ? 'button--busy-loading' : ''} ${success ? 'button--busy-success' : ''} ${className}`}
            type={type}
            title={title}
            onClick={onClick}
            onKeyDown={isBusy ? (e) => e.preventDefault() : undefined}
            disabled={disabled}
        >
            <span className="button__text">{children}</span>
            {isBusy && (
                <span className="button__loading">
                    <span>{loadingText}</span>
                    {isBusy && <BusyIcon animate={isBusy} />}
                </span>
            )}
            {success && (
                <span className="button__success">
                    <span>{successText}</span>
                    <BaseIcon>
                        <IconStatusSuccess />
                    </BaseIcon>
                </span>
            )}
        </button>
    );
};
ButtonBusy.defaultProps = {
    type: 'button',
    title: undefined,
    className: '',
    loadingText: 'Loading',
    onClick: undefined,
    isBusy: false,
    disabled: false,
    successText: 'Done',
    isSuccess: undefined,
};
ButtonBusy.propTypes = {
    type: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    title: PropTypes.string,
    loadingText: PropTypes.string,
    successText: PropTypes.string,
    onClick: PropTypes.func,
    isBusy: PropTypes.bool,
    disabled: PropTypes.bool,
    isSuccess: PropTypes.bool,
};
export default ButtonBusy;
